<br><br>

<div  class="container mt-5">
  <h5>ENVIA:</h5>
  <p>
    <strong [style.color]="(this.at.NombreUsuario && this.at.NombreUsuario.trim()) ? 'green' : 'red'">
      <i class="fas fa-user"></i> 
      {{ this.at.NombreUsuario || 'Nombre requerido (modifica en tu perfil)' }}
    </strong>
    <br>
    <span [style.color]="(this.at.numero != null && this.at.numero !== '') ? 'green' : 'red'">
      <i class="fas fa-phone"></i>  
      {{ this.at.numero || 'Número requerido (modifica en tu perfil)' }} 
    </span>
    <br>
    <span [style.color]="(this.at.Direccion && this.at.Direccion.trim()) ? 'green' : 'red'">
      <i class="fas fa-map-marker-alt"></i> 
      {{ this.at.Direccion || 'Dirección requerida (modifica en tu perfil)' }}
    </span>
    <br>
    <span [style.color]="(this.at.CorreoUsuario && this.at.CorreoUsuario.trim()) ? 'green' : 'red'">
      <i class="fas fa-envelope"></i> 
      {{ this.at.CorreoUsuario || 'Correo requerido (modifica en tu perfil)' }}
    </span>
  </p>
  
  
  
    <h1 class="text-center">DATOS DEL DESTINATARIO</h1>
    <form [formGroup]="formulario" (ngSubmit)="onSubmit()">
      <div *ngIf="formulario.get('nombre').errors && formulario.get('nombre').touched" class="text-danger">
        <div *ngIf="formulario.get('nombre').hasError('required')">
          El campo nombre es requerido.
        </div>
      </div>
      <div class="input-group">
        <i class="fas fa-user"></i>
        <input type="text" formControlName="nombre" placeholder="Nombre">
      </div>

      <div *ngIf="formulario.get('numero').errors && formulario.get('numero').touched" class="text-danger">
        <div *ngIf="formulario.get('numero').hasError('required')">
          El campo número es requerido.
        </div>
        <div *ngIf="formulario.get('numero').hasError('pattern')">
          El campo número debe contener exactamente 8 dígitos.
        </div>
      </div>
      <div class="input-group">
        <i class="fas fa-phone"></i>
        <input type="number" formControlName="numero" placeholder="Número" (wheel)="onWheel($event)">
      </div>

      <div *ngIf="formulario.get('direccion').errors && formulario.get('direccion').touched" class="text-danger">
        <div *ngIf="formulario.get('direccion').hasError('required')">
          La dirección es requerida.
        </div>
      </div>
      <div class="input-group">
        <i class="fas fa-map-marker-alt"></i>
        <textarea formControlName="direccion" placeholder="Dirección, incluye departamento y municipio" rows="6"></textarea>
      </div>

      
      <div class="form-check">
        <input type="checkbox" formControlName="soloEnvio" id="soloEnvio" (change)="actualizarMonto()">
        <label for="soloEnvio">MARCAR SI EL MONTO ES CERO</label>
      </div>
      <div *ngIf="formulario.get('cobrar').errors && formulario.get('cobrar').touched" class="text-danger">
        <div *ngIf="formulario.get('cobrar').hasError('required')">
          El campo es requerido.
        </div>
        <div *ngIf="formulario.get('cobrar').hasError('pattern')">
          El campo debe tener como máximo dos decimales.
        </div>
        <div *ngIf="formulario.get('cobrar').hasError('min')">
          El valor debe ser mayor que cero.
        </div>
      </div>
      
      
      <div class="input-group" id="input-group">
        <i class="fas fa-coins"></i>
        <input type="number" formControlName="cobrar" placeholder="Monto a Cobrar" class="input-monto" (wheel)="onWheel($event)">
      </div>

      <div class="detalles">
        <p>ENVÍO: Q. {{ costoEnvio | number:'1.2-2' }}</p>
        <p>COD: Q. {{ montoTotalACobrar }}</p>
        <p>COMISION: Q. {{ this.comision | number:'1.2-2' }}</p>
        <h3 class="text-center" [ngStyle]="{'color': montoTotalARecibir > 0 ? 'green' : montoTotalARecibir < 0 ? 'red' : 'black'}">
            MONTO TOTAL A RECIBIR: Q. {{ montoTotalARecibir | number:'1.2-2' }}
          </h3>          
      </div>

      <button  type="submit"> Enviar</button>
    </form>
</div>

