<br>
<div class="card mt-5 mb-3">
  <h1 class="text-center">RUTA DE GUIA {{guia}}</h1>
</div>

<div class="map-container " >
  <google-map  [zoom]="15"  [center]="{lat: lat, lng:lng}" height="{{600}}px" width="{{100}}%" [options] = "map">
    <map-marker *ngFor="let markerPos of markerPositions"  [position]="markerPos" [options]="markerOp"></map-marker>
  </google-map>
</div>
