import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import { WebsocketService } from '@app/services/websocket.service';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NavbarComponent } from '.././navbar/navbar.component'
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  variable: string;
  mensajes: any[];
  guia: any;
  mensajeFiltrado: any;
  loaded: boolean = false;
   // Variable para almacenar la suscripción
   public mensajesSubscription: Subscription;
  sala: any;
  conductor: string;
  constructor(public navbar: NavbarComponent, private cdRef: ChangeDetectorRef,private route: ActivatedRoute,public socket: WebsocketService) { 
  }
  scrollToBottom() {
    const height = document.documentElement.scrollHeight;
    window.scrollTo({
      top: height,
    });
    console.log('ejecutandose')
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.scrollToBottom()
    }, 900);
    this.sala = this.route.snapshot.paramMap.get('Sala');
    this.guia = this.route.snapshot.paramMap.get('Guia');
    this.conductor = this.route.snapshot.paramMap.get('Conductor');
    // Suscribirse a la sala correspondiente
    this.socket.suscribirse(this.sala,this.guia);
    // Obtener los mensajes de la sala
    this.mensajesSubscription = this.socket.getMensajes().subscribe(data => {
      this.scrollToBottom()

      if (data?.filter) {
        this.mensajes = data.filter(mensaje =>  mensaje.sala === this.sala);
        console.log(this.mensajes);
        this.scrollToBottom()
      }
      else {
        // Si no hay filtro, asignar todos los mensajes
        this.mensajes = data;
        this.scrollToBottom()
      }
    });

    let isTimeoutActivated = localStorage.getItem('isTimeoutActivated');
    console.log(isTimeoutActivated)
    if (isTimeoutActivated === null) {
      // Si isTimeoutActivated no está en el almacenamiento local, establecerlo en true y activar el temporizador
      localStorage.setItem('isTimeoutActivated', 'true');
      
      setTimeout(() => {
        // Después de 1 segundos, eliminar isTimeoutActivated del almacenamiento local y recargar la página
        location.reload()        
      }, 1000);
    } else {
      // Si isTimeoutActivated ya está en el almacenamiento local, no hacer nada
      console.log('Timeout ya activado');

    }   

  }

  ngOnDestroy(): void {
    // Desuscribirse de la sala correspondiente
      this.socket.desuscribirse(this.sala);
      // Cancelar la suscripción a los mensajes
      this.mensajesSubscription.unsubscribe();
      localStorage.removeItem('isTimeoutActivated');
  }
 abrirChat(): void {
    // Mostrar ventana emergente de chat
    Swal.fire({
      title:'Escribe tu mensaje',
      input: 'text',
      position: 'bottom'
    }).then((result) => {
 if (result.dismiss || result.value =='')
 {
  return
 }

 else
    this.socket.abrirChat(this.guia,result.value,this.conductor)
              // Conexión WebSocket
  });
  this.scrollToBottom()
  }

}
