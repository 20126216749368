import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TermsService {

  constructor(private afs: AngularFirestore) { }

  checkTermsAcceptance(userId: string): Observable<boolean> {
    return this.afs.collection('Usuarios').doc(userId).valueChanges().pipe(
      map((userData: any) => !!userData.termsAccepted)
    );
  }

  getTermsAndConditions(): Observable<any> {
    return this.afs.collection('terms-and-conditions').doc('latest').valueChanges();
  }

  setUserAcceptance(userId: string, documentId: string): Promise<void> {
    return this.afs.collection('Usuarios').doc(userId).update({
      [`termsAccepted.${documentId}`]: true
    });
  }
}
