import {User} from '@shared/models/user.interface';

export class RoleValidator
{
    isUsuario(user: User):boolean {
    return user.role == 'USUARIO'; 
    }
    isAdmin(user: User):boolean {
    return user.role == 'ADMIN';
    }
    isVery(user: User):boolean {
        return user.emailVerified == true;
        }
}