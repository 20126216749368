<div class="row mt-5 pt-5">

    <div  style="background-color: #ffffff">
        <div class="card-body col-12 text-center mb-0">
                    <img src="./../../../assets/ISOTIPO.png" class="img-fluid"  ><br>
                    <img src="./../../../assets/NOMBRE.png" class="img-fluid" style="font-size: 4px">
            <br>  
        </div>
    </div>
 

    <footer class=" text-center fixed-bottom mt-0 p-0">
    <div class="row m-0 p-0" style="background-color: #3CBFF0; border-top-left-radius: 30%;margin-top: -70px;">
      <div class="col-12" style="color: #ffffff">
<br>
          <h1 class="">BIENVENIDO</h1>
          <p class="mb-0">Plataforma lider en servicios de DELIVERY</p> 
          <p class="mb-0">Si ya tienes una cuenta Inicia sesion</p> 
          <p>De lo contrario registrate</p> 
      </div>
      <div class="col-12 text-center"> <br>
        <button style="color: white" class="btn btn-outline-light mr-2"> <h5>INICIAR SESION</h5></button>
        <button class="btn  btn-light ml-2"><h5>REGISTRAR</h5></button>
      </div>
           
<br>
            <br><br><br>
    </div>
</footer>
</div>