import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductoComponent } from './productos/producto/producto.component';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";


@NgModule({
  declarations: [ProductoComponent],
  imports: [
    CommonModule, FormsModule,GoogleMapsModule,GooglePlaceModule
  ],
  exports: [
    ProductoComponent
  ]
})
export class EnviosModule { }
