// terms-modal.component.ts
import { Component, OnInit } from '@angular/core';
import { TermsService } from '../terms.service';
import Swal from 'sweetalert2';
import { AuthService } from '../auth/services/auth.service';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.css']
})
export class TermsModalComponent implements OnInit {
  termsContent: string = '';
  documentId: string = '';

  constructor(private termsService: TermsService, public at: AuthService) {}

  ngOnInit(): void {
    this.termsService.getTermsAndConditions().subscribe(terms => {
      this.termsContent = terms.content;
      this.documentId = terms.id;
      this.showTermsModal();
    });
  }

  showTermsModal(): void {
    Swal.fire({
      title: 'Términos y Condiciones',
      html: `<div style="max-height: 400px; overflow-y: auto;">${this.termsContent}</div>`,
      showCancelButton: true,
      confirmButtonText: 'Acepto Términos y Condiciones',
      cancelButtonText: 'Cancelar',
      preConfirm: () => {
        return this.termsService.setUserAcceptance(this.at.IdUsuario, this.documentId);
      }
    }).then((result) => {
      if (result.value) {
        Swal.fire('¡Aceptado!', 'Has aceptado los términos y condiciones.', 'success');
      } else {
        Swal.fire('Cancelado', 'No has aceptado los términos y condiciones.', 'error');
      }
    });
  }
}
