import{AuthService} from '@auth/services/auth.service'
import { Component, OnDestroy} from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
  providers:[AuthService],
})
export class SendEmailComponent implements OnDestroy {
  public user$: Observable<any>= this.authSvc.afAuth.user;
  constructor(private authSvc:AuthService,private router: Router ) {}

  onSendEmail(): void {
this.authSvc.sendverificationEmail();
  }

  ngOnDestroy(){
    this.authSvc.logout();
    this.router.navigate(['/login'])
  }
}
