import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cotizar',
  templateUrl: './cotizar.component.html',
  styleUrls: ['./cotizar.component.scss']
})
export class CotizarComponent implements OnInit {
  zonaInicio: string = '';
  zonaFinal: string = '';
  precio: number = 0;
  constructor() {
   }

  ngOnInit(): void {
    this.capturarZonas()
  }

 async capturarZonas() {
    Swal.mixin({
      input: 'text',
      confirmButtonText: 'Siguiente &rarr;',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      progressSteps: ['1', '2'],
    })
      .queue([
        {
          footer: '<strong>tip: Selecciona la Zona de Recoleccion*</strong>',
          title: 'ZONA DE RECOLECCION:',
          input: 'select',
          inputOptions: {
            'zona01': 'ZONA 01',
            'zona02': 'ZONA 02',
            'zona03': 'ZONA 03',
            'zona04': 'ZONA 04',
            'zona05': 'ZONA 05',
            'zona06': 'ZONA 06',
            'zona07': 'ZONA 07',
            'zona08': 'ZONA 08',
            'zona09': 'ZONA 09',
            'zona10': 'ZONA 10',
            'zona11': 'ZONA 11',
            'zona12': 'ZONA 12',
            'zona13': 'ZONA 13',
            'zona14': 'ZONA 14',
            'zona15': 'ZONA 15',
            'zona16': 'ZONA 16',
            'zona17': 'ZONA 17',
            'zona18': 'ZONA 18',
            'zona19': 'ZONA 19',
            'zona21': 'ZONA 21',
            'mixco1': 'MIXCO (zonas: 1, 2, 3, 4)',
            'mixco2': 'MIXCO (zonas: 5, 6, 7, 8, 9, 10, 11)',
            'villanueva': 'VILLA NUEVA',
            'sanmiguelpetapa': 'SAN MIGUEL PETAPA',
            'villacanales': 'VILLA CANALES',
            'amatitlan': 'AMATITLAN',
            'fraijanes': 'FRAIJANES',
            'sanjosepinula': 'SAN JOSE PINULA',
            'santacatarinapinula': 'SANTA CATARINA PINULA',
            'chinautla': 'CHINAUTLA'
          },
    
          inputValidator: (value) => {
            if (!value) {
              return 'Este campo es obligatorio';
            }
          }
        },
        {
          footer: '<strong>tip: Selecciona la Zona de Entrega*</strong>',
          title: 'ZONA DE ENTREGA:',
          input: 'select',
          inputOptions: {
            'zona01': 'ZONA 01',
            'zona02': 'ZONA 02',
            'zona03': 'ZONA 03',
            'zona04': 'ZONA 04',
            'zona05': 'ZONA 05',
            'zona06': 'ZONA 06',
            'zona07': 'ZONA 07',
            'zona08': 'ZONA 08',
            'zona09': 'ZONA 09',
            'zona10': 'ZONA 10',
            'zona11': 'ZONA 11',
            'zona12': 'ZONA 12',
            'zona13': 'ZONA 13',
            'zona14': 'ZONA 14',
            'zona15': 'ZONA 15',
            'zona16': 'ZONA 16',
            'zona17': 'ZONA 17',
            'zona18': 'ZONA 18',
            'zona19': 'ZONA 19',
            'zona21': 'ZONA 21',
            'mixco1': 'MIXCO (zonas: 1, 2, 3, 4)',
            'mixco2': 'MIXCO (zonas: 5, 6, 7, 8, 9, 10, 11)',
            'villanueva': 'VILLA NUEVA',
            'sanmiguelpetapa': 'SAN MIGUEL PETAPA',
            'villacanales': 'VILLA CANALES',
            'amatitlan': 'AMATITLAN',
            'fraijanes': 'FRAIJANES',
            'sanjosepinula': 'SAN JOSE PINULA',
            'santacatarinapinula': 'SANTA CATARINA PINULA',
            'chinautla': 'CHINAUTLA'
          },
    
          inputValidator: (value) => {
            if (!value) {
              return 'Este campo es obligatorio';
            }
          }
        },
      ])
      .then(async (result) => {
        if (result.value) {
          // result.value es un array con las respuestas del usuario
           this.zonaInicio = result.value[0];
          this.zonaFinal = result.value[1];


          // Aquí puedes hacer lo que quieras con las zonas (por ejemplo, calcular el precio)
          // Y luego mostrar otro SweetAlert con el resultado.

          const precio = await this.calcularTarifaAsync(this.zonaInicio, this.zonaFinal);
          Swal.fire(`Envio: Q.${precio}`,` DESDE: <strong>${this.zonaInicio}</strong> <br> HACIA: <strong>${this.zonaFinal}</strong> <br> <br> <strong> obten beneficios extras al ser VIP</strong>`, 'success');
        }
      });
  }

  private tarifas = [
    { grupoInicio: 'A', grupoDestino: 'C', costo: 30 },
    { grupoInicio: 'A', grupoDestino: 'D', costo: 30 },
    { grupoInicio: 'A', grupoDestino: 'E', costo: 30 },
    { grupoInicio: 'A', grupoDestino: 'F', costo: 35 },
    { grupoInicio: 'B', grupoDestino: 'D', costo: 35 },
    { grupoInicio: 'A', grupoDestino: 'B', costo: 40 },
    { grupoInicio: 'A', grupoDestino: 'G', costo: 40 },
    { grupoInicio: 'B', grupoDestino: 'C', costo: 40 },
    { grupoInicio: 'B', grupoDestino: 'E', costo: 35 },
    { grupoInicio: 'B', grupoDestino: 'F', costo: 40 },
    { grupoInicio: 'B', grupoDestino: 'G', costo: 40 },
    { grupoInicio: 'C', grupoDestino: 'E', costo: 30 },
    { grupoInicio: 'D', grupoDestino: 'E', costo: 30 },
    { grupoInicio: 'C', grupoDestino: 'F', costo: 35 },
    { grupoInicio: 'C', grupoDestino: 'G', costo: 40 },
    { grupoInicio: 'D', grupoDestino: 'F', costo: 40 },
    { grupoInicio: 'D', grupoDestino: 'G', costo: 40 },
    { grupoInicio: 'E', grupoDestino: 'F', costo: 30 },
    { grupoInicio: 'F', grupoDestino: 'G', costo: 35 },
    { grupoInicio: 'E', grupoDestino: 'G', costo: 40 },
    { grupoInicio: 'A', grupoDestino: 'H', costo: 35 },
    { grupoInicio: 'B', grupoDestino: 'H', costo: 40 },
    { grupoInicio: 'C', grupoDestino: 'H', costo: 30 },
    { grupoInicio: 'D', grupoDestino: 'H', costo: 35 },
    { grupoInicio: 'E', grupoDestino: 'H', costo: 35 },
    { grupoInicio: 'F', grupoDestino: 'H', costo: 35 },
    { grupoInicio: 'G', grupoDestino: 'H', costo: 40 },
    { grupoInicio: 'C', grupoDestino: 'D', costo: 35 },
    { grupoInicio: 'A', grupoDestino: 'A', costo: 25 }, // Agrega esta línea
    { grupoInicio: 'B', grupoDestino: 'B', costo: 25 }, // Agrega esta línea
    { grupoInicio: 'C', grupoDestino: 'C', costo: 25 }, // Agrega esta línea
    { grupoInicio: 'D', grupoDestino: 'D', costo: 25 }, // Agrega esta línea
    { grupoInicio: 'E', grupoDestino: 'E', costo: 25 }, // Agrega esta línea
    { grupoInicio: 'F', grupoDestino: 'F', costo: 25 }, // Agrega esta línea
    { grupoInicio: 'G', grupoDestino: 'G', costo: 25 }, // Agrega esta línea
    { grupoInicio: 'H', grupoDestino: 'H', costo: 25 }  // Agrega esta línea
  ];
  
  private grupos: { [zona: string]: string } = {
    'zona02': 'A',
    'zona03': 'A',
    'zona04': 'A',
    'zona05': 'A',
    'zona01': 'A',
    'zona07': 'A',
    'zona08': 'A',
    'sanjosepinula': 'B',
    'santacatarinapinula': 'B',
    'fraijanes': 'B',
    'zona19': 'C',
    'mixco1': 'C',
    'zona16': 'D',
    'zona06': 'D',
    'zona18': 'D',
    'zona17': 'D',
    'chinautla': 'D',
    'zona09': 'E',
    'zona15': 'E',
    'zona10': 'E',
    'zona14': 'E',
    'zona13': 'E',
    'zona12': 'E',
    'zona11': 'E',
    'zona21': 'F',
    'villanueva': 'F',
    'sanmiguelpetapa': 'F', 
    'villacanales': 'F',
    'amatitlan': 'G',
    'mixco2': 'H'
  };

  determinarGrupo(zona: string): string {
    // Determina el grupo de la zona a partir de la tabla de grupos.
    const grupo = this.grupos[zona] || 'Desconocido';
    return grupo;
  }

  calcularTarifaAsync(zonaInicio: string, zonaDestino: string): Promise<number> {
    return new Promise((resolve, reject) => {

      const grupoInicio = this.determinarGrupo(zonaInicio);
      const grupoDestino = this.determinarGrupo(zonaDestino);
  
      console.log(grupoInicio, 'INICIO');
      console.log(grupoDestino, 'FIN');
  
      if (grupoInicio === grupoDestino) {
        // Verifica si el UID está en la lista VI
  
        // Si el usuario no es VIP, la tarifa es de 30 (25 + 5)
        const tarifaFinal = 30;
  
        resolve(tarifaFinal);
        console.log(tarifaFinal);
        return; // Importante añadir return para evitar que se ejecute la lógica siguiente si es del mismo grupo
      }
  
      const tarifa = this.tarifas.find(t =>
        (t.grupoInicio === grupoInicio && t.grupoDestino === grupoDestino) ||
        (t.grupoInicio === grupoDestino && t.grupoDestino === grupoInicio)
      );
  
      if (tarifa) {
        // Si el usuario no es VIP, suma 5 a la tarifa

        const tarifaFinal = tarifa.costo + 5;
  
        resolve(tarifaFinal);
        console.log(tarifaFinal);
      } else {
        reject(50); // O maneja el error de alguna otra manera.
      }
    });
  }
  


}
