import { BrowserModule } from '@angular/platform-browser';
import { HomeModule } from './home/home.module';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import {HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { SendEmailComponent } from './auth/send-email/send-email.component';
import { AuthService } from './auth/services/auth.service';
import { CanEditGuard } from './auth/guards/can-edit.guard';
import { CanAdminGuard } from './auth/guards/can-admin.guard';
//google maps
import{GoogleMapsModule}from '@angular/google-maps'
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
//firebase
import {AngularFireDatabaseModule} from 'angularfire2/database';
//servicios
import{ProductService}from './services/product.service';
// envios
import { EnviosModule } from './envios/envios.module';
import { InicioComponent } from './auth/inicio/inicio.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ToastrModule, ToastNoAnimationModule } from 'ngx-toastr';
import { ChatComponent } from './shared/chat/chat.component';
import { RutaComponent } from './shared/ruta/ruta.component';

import { TermsService } from './terms.service';
import { TermsModalComponent } from './terms-modal/terms-modal.component'; 
@NgModule({
  declarations: [
    AppComponent, NavbarComponent, SendEmailComponent, InicioComponent, ChatComponent, RutaComponent,TermsModalComponent],
  imports: [ 
    ToastrModule.forRoot({
    timeOut: 3000,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
 
  }),
  ToastNoAnimationModule.forRoot(),
    BrowserModule,
    AngularFireDatabaseModule,
    AppRoutingModule,
    EnviosModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    GoogleMapsModule,
    GooglePlaceModule,
    HttpClientModule
  ],
  providers: [AuthService, CanEditGuard,CanAdminGuard,ProductService,TermsService ,
    {
      provide: LocationStrategy,useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }