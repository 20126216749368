import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore'
import Swal from 'sweetalert2';

import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ProductService{ 
  private listaVIPSubject = new BehaviorSubject<string[]>([]);
  listaVIP$ = this.listaVIPSubject.asObservable();
  distanciazonas={
    zona1:[14.637922086731622,-90.51046719014914],
    zona2:[14.661276851232076,-90.50926364782057],
    zona3:[14.63333925692703,-90.5287035464112],
    zona4:[14.619571955420053,-90.51702655883962],
    zona5:[14.626667607720663,-90.49755616118252],
    zona6:[14.675859266523487,-90.48361505804098],
    zona7:[14.642343026252712,-90.53845301949121],
    zona8:[14.616601636763487,-90.52728781166121],
    zona9:[14.60703291636648,-90.5217779649531],
    zona10:[14.58994134135333,-90.50329004796802],
    zona11:[14.601935943684428, -90.5614920781125],
    zona12:[14.575540691048932, -90.55317428033734],
    zona13:[14.578329714865012, -90.5312172530711],
    zona14:[14.581398208151214, -90.51360927073256],
    zona15:[14.596643815020867, -90.4939944694016],
    zona16:[14.608905505831604, -90.4715236846791],
    zona17:[14.645723525625783, -90.45931281205095],
    zona18:[14.672485645846507, -90.45689331642885],
    zona19:[14.656195916105009, -90.57850725006553],
    zona21:[14.548963426575785, -90.54797255463933],
    zona24:[14.61852644643869, -90.4371848870188],
    zona25:[14.687743172750462, -90.39928703947746],
    Mixco:[14.662682293488889, -90.58746184912629],
    Villa_Nueva:[14.52276531155793, -90.59472456904567],
    cVilla_Nueva:[14.550712857902314, -90.57875466876847]
  }
    esVIP: Observable<boolean>; 
  esVIPKey: string;
    constructor(public fs: AngularFirestore) {
      this.actualizarListaVIP();   
    }

    esVIPs(): boolean {
      const storedValue = localStorage.getItem(this.esVIPKey);
      return storedValue ? JSON.parse(storedValue) : false;
    }

    private actualizarListaVIP() {
      this.fs
        .collection('clientesVIP').doc('lista')
        .valueChanges()
        .subscribe((documentData: any) => {
          if (documentData && documentData.listaVIP && Array.isArray(documentData.listaVIP)) {
            const listaVIP = documentData.listaVIP.filter(Boolean);
            this.listaVIPSubject.next(listaVIP);
          } else {
            console.error('La respuesta de clientesVIP no es válida:', documentData);
          }
        });
    }
    setEsVIPInLocalStorage(value: boolean,id): void {
      localStorage.setItem(id ,JSON.stringify(value));
    }

    obtenerListaVIP(): Observable<string[]> {
      return this.listaVIP$;
    }
  
    public inicializarEsVIPObservable(id) {
      this.esVIP = this.listaVIP$.pipe(
        map((listaVIP: string[]) => listaVIP.includes(id))
      );
    }
    seleccionar_avatar(tipo: string){
    if(tipo == "PERSONAL"){
      Swal.fire({
        title: 'Selecciona tu avatar Personal',
        input: 'radio',
        inputOptions:{
          1: '<img src="../assets/1.png" class="rounded-circle img-thumbnail" alt="profile-image"></div>',
          2: '<img src="../assets/2.png" class="rounded-circle img-thumbnail" alt="profile-image"></div>',
          3: '<img src="../assets/3.png" class="rounded-circle img-thumbnail" alt="profile-image"></div>',
          4: '<img src="../assets/4.png" class="rounded-circle img-thumbnail" alt="profile-image"></div>',
          5: '<img src="../assets/5.png" class="rounded-circle img-thumbnail" alt="profile-image"></div>',
          6: '<img src="../assets/6.png" class="rounded-circle img-thumbnail" alt="profile-image"></div>',
          7: '<img src="../assets/7.png" class="rounded-circle img-thumbnail" alt="profile-image"></div>',
          8: '<img src="../assets/8.png" class="rounded-circle img-thumbnail" alt="profile-image"></div>',
        },
        inputValidator: (value) => {
          console.log(value)
          localStorage.setItem(tipo,value)
          if (!value) {
            return 'Selecciona una opcion'
          }
        }
      }
      )
    }if(tipo == "Empresa"){
      console.log("Es una Empresa")
    } 
      //  localStorage.setItem(variable,numero)
    }
    
    zona2(pedido,guia)
    { 
     return this.fs.collection('Pedidos').doc(guia).set(pedido)
    }

    
    dist(dato) {
      const arreglo = Object.entries(this.distanciazonas);
      let minimo = Infinity;
      let resultado = ""; 
    
      for (let i = 0; i < arreglo.length; i++) {
        const zona = arreglo[i][0];
        const coords = arreglo[i][1];
        const distancia = this.distancia(coords[0], dato.lat, coords[1], dato.lng);
    
        if (distancia < minimo) {
          minimo = distancia;
          resultado = zona;
        }
      }
    
      console.log("distancia con comodin", resultado);
      return resultado;
    }


    distancia(lat1, lat2, lng1, lng2) {
      const earthRadius = 6371; // radio de la Tierra en km
      const degToRad = Math.PI / 180;
      const lat1Rad = lat1 * degToRad;
      const lat2Rad = lat2 * degToRad;
      const lng1Rad = lng1 * degToRad;
      const lng2Rad = lng2 * degToRad;
    
      // cálculo de los vectores de los puntos
      const x1 = Math.cos(lat1Rad) * Math.cos(lng1Rad);
      const y1 = Math.cos(lat1Rad) * Math.sin(lng1Rad);
      const z1 = Math.sin(lat1Rad);
      const x2 = Math.cos(lat2Rad) * Math.cos(lng2Rad);
      const y2 = Math.cos(lat2Rad) * Math.sin(lng2Rad);
      const z2 = Math.sin(lat2Rad);
    
      // cálculo de la distancia utilizando la fórmula del producto punto
      const distance = earthRadius * Math.acos(x1 * x2 + y1 * y2 + z1 * z2);
    
      return distance;
    }
    private tarifas = [
      { grupoInicio: 'A', grupoDestino: 'C', costo: 30 },
      { grupoInicio: 'A', grupoDestino: 'D', costo: 30 },
      { grupoInicio: 'A', grupoDestino: 'E', costo: 30 },
      { grupoInicio: 'A', grupoDestino: 'F', costo: 35 },
      { grupoInicio: 'B', grupoDestino: 'D', costo: 35 },
      { grupoInicio: 'A', grupoDestino: 'B', costo: 40 },
      { grupoInicio: 'A', grupoDestino: 'G', costo: 40 },
      { grupoInicio: 'B', grupoDestino: 'C', costo: 40 },
      { grupoInicio: 'B', grupoDestino: 'E', costo: 35 },
      { grupoInicio: 'B', grupoDestino: 'F', costo: 40 },
      { grupoInicio: 'B', grupoDestino: 'G', costo: 40 },
      { grupoInicio: 'C', grupoDestino: 'E', costo: 30 },
      { grupoInicio: 'D', grupoDestino: 'E', costo: 30 },
      { grupoInicio: 'C', grupoDestino: 'F', costo: 35 },
      { grupoInicio: 'C', grupoDestino: 'G', costo: 40 },
      { grupoInicio: 'D', grupoDestino: 'F', costo: 40 },
      { grupoInicio: 'D', grupoDestino: 'G', costo: 40 },
      { grupoInicio: 'E', grupoDestino: 'F', costo: 30 },
      { grupoInicio: 'F', grupoDestino: 'G', costo: 35 },
      { grupoInicio: 'E', grupoDestino: 'G', costo: 40 },
      { grupoInicio: 'A', grupoDestino: 'H', costo: 35 },
      { grupoInicio: 'B', grupoDestino: 'H', costo: 40 },
      { grupoInicio: 'C', grupoDestino: 'H', costo: 30 },
      { grupoInicio: 'D', grupoDestino: 'H', costo: 35 },
      { grupoInicio: 'E', grupoDestino: 'H', costo: 35 },
      { grupoInicio: 'F', grupoDestino: 'H', costo: 35 },
      { grupoInicio: 'G', grupoDestino: 'H', costo: 40 },
      { grupoInicio: 'C', grupoDestino: 'D', costo: 35 },
      { grupoInicio: 'A', grupoDestino: 'A', costo: 25 }, 
      { grupoInicio: 'B', grupoDestino: 'B', costo: 25 }, 
      { grupoInicio: 'C', grupoDestino: 'C', costo: 25 }, 
      { grupoInicio: 'D', grupoDestino: 'D', costo: 25 }, 
      { grupoInicio: 'E', grupoDestino: 'E', costo: 25 }, 
      { grupoInicio: 'F', grupoDestino: 'F', costo: 25 }, 
      { grupoInicio: 'G', grupoDestino: 'G', costo: 25 }, 
      { grupoInicio: 'H', grupoDestino: 'H', costo: 25 }  
    ];
    
    private grupos: { [zona: string]: string } = {
      'zona02': 'A',
      'zona03': 'A',
      'zona04': 'A',
      'zona05': 'A',
      'zona01': 'A',
      'zona07': 'A',
      'zona08': 'A',
      'sanjosepinula': 'B',
      'santacatarinapinula': 'B',
      'fraijanes': 'B',
      'zona19': 'C',
      'mixco1': 'C',
      'zona16': 'D',
      'zona06': 'D',
      'zona18': 'D',
      'zona17': 'D',
      'chinautla': 'D',
      'zona09': 'E',
      'zona15': 'E',
      'zona10': 'E',
      'zona14': 'E',
      'zona13': 'E',
      'zona12': 'E',
      'zona11': 'E',
      'zona21': 'F',
      'villanueva': 'F',
      'sanmiguelpetapa': 'F', 
      'villacanales': 'F',
      'amatitlan': 'G',
      'mixco2': 'H'
    };
  
    determinarGrupo(zona: string): string {
      // Determina el grupo de la zona a partir de la tabla de grupos.
      const grupo = this.grupos[zona] || 'Desconocido';
      return grupo;
    }
  
    calcularTarifaAsync(zonaInicio: string, zonaDestino: string, VIP): Promise<number> {
      return new Promise((resolve, reject) => {
        // Lista de UID VIP
    
        const grupoInicio = this.determinarGrupo(zonaInicio);
        const grupoDestino = this.determinarGrupo(zonaDestino);
    
        console.log(grupoInicio, 'INICIO');
        console.log(grupoDestino, 'FIN');
    
        if (grupoInicio === grupoDestino) {
          const tarifaFinal = VIP ? 25 : 30;
    
          resolve(tarifaFinal);
          console.log(tarifaFinal, VIP);
          return; 
        }
    
        const tarifa = this.tarifas.find(t =>
          (t.grupoInicio === grupoInicio && t.grupoDestino === grupoDestino) ||
          (t.grupoInicio === grupoDestino && t.grupoDestino === grupoInicio)
        );
    
        if (tarifa) {
          // Si el usuario no es VIP, suma 5 a la tarifa
          let tarifaFinal = VIP ? tarifa.costo : tarifa.costo + 5;
      // Aplicar condición adicional
      if (zonaInicio === 'zona07' && grupoDestino === 'E') {
        tarifaFinal -= 5;
      }

          resolve(tarifaFinal);
          console.log(tarifaFinal);
        } else {
          reject(50); 
        }
      });
    }
    
    

}
