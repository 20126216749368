import { Component,ViewChild, NgZone, ElementRef } from '@angular/core';
import { ProductService } from '../../../services/product.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { GoogleMap } from '@angular/google-maps';
import { AngularFirestore }  from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AuthService } from '@app/auth/services/auth.service';
import html2canvas from 'html2canvas';
import * as JsBarcode from 'jsbarcode';
import * as firebase from 'firebase/app';
import 'firebase/firestore'
import { map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare var google:any;

@Component(
  {selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss'],
  })
export class ProductoComponent 
{
  telegramBotToken = environment.telegramToken;
  chatId = environment.ad;
  grupo= environment.grupo
  @ViewChild('htmlData') htmlData!: ElementRef;
  localStorageKey = 'pageReloaded';
  fecha = firebase.firestore.FieldValue.serverTimestamp();
  fechajavas= Date.now()
  Precio: any;
  Precio1: number;
  CDSC: any;
  limitesguatemala ={ 
  north: 14.70, //arriba
  south: 14.48, //abajo
  west: -90.64, //izquierda
  east:  -90.360824
  };
  UBICACIONES$: any;
  UbicacionSeleccionada : any
  selectedDocumentId: string;
  ubicaciones: any;
  VistaDirecciones1: boolean = false;
  zonafinal: any;
  zonainicio: any;
  private googleAutocomplete: google.maps.places.AutocompleteService;
constructor(private http:HttpClient,private ngZone: NgZone, private afs: AngularFirestore ,public ProductService: ProductService, public auth:AuthService,) 
{
  document.addEventListener('DOMContentLoaded', () => {
    this.googleAutocomplete = new google.maps.places.AutocompleteService();
  }); 
}


async cotizar() {
  const zonaInicio = await this.solicitarZonaInicio();

  if (zonaInicio) {
    try {
      const tarifasAgrupadas: { [tarifa: string]: string[] } = {};

      const zonasDestino = [
        'zona02', 'zona03', 'zona04', 'zona05', 'zona01', 'zona07', 'zona08',
        'sanjosepinula', 'santacatarinapinula', 'fraijanes',
        'zona19', 'mixco1',
        'zona16', 'zona06', 'zona18', 'zona17', 'chinautla',
        'zona09', 'zona15', 'zona10', 'zona14', 'zona13', 'zona12', 'zona11',
        'zona21', 'villanueva', 'sanmiguelpetapa', 'villacanales',
        'amatitlan', 'mixco2'
      ];

      for (const zonaDestino of zonasDestino) {
        const tarifa = await this.ProductService.calcularTarifaAsync(zonaInicio, zonaDestino, this.esVIP);

        if (!tarifasAgrupadas[tarifa]) {
          tarifasAgrupadas[tarifa] = [];
        }

        tarifasAgrupadas[tarifa].push(zonaDestino);
      }

      let mensaje = '<div style="font-family: Arial, sans-serif; font-size: 17px; line-height: 1.6; padding: 20px; background-color: #f8f8f8; border-radius: 15px;">';
      mensaje += '<h2 style="color: #333; text-align: center;">Tarifas por Zona de Destino</h2>';
      
      for (const [tarifa, zonas] of Object.entries(tarifasAgrupadas)) {
        mensaje += `<p style="margin-bottom: 10px;"> <strong>Tarifa:</strong> Q.${tarifa} <br>${zonas.join(', ')}</p>`;
      }
      
      await Swal.fire({
        title: '', // Título vacío para evitar duplicar el encabezado
        html: mensaje,
        icon: 'info',
        confirmButtonText: 'OK',
        customClass: {
          popup: 'my-custom-popup-class',
          title: 'my-custom-title-class',
          content: 'my-custom-content-class',
          confirmButton: 'my-custom-confirm-button-class'
        }
      });
    } catch (error) {
      console.error('Error al cotizar:', error);
    }
  }
}

async solicitarZonaInicio() {
  const zonasDestino = {
    'zona02': 'Zona 02',
    'zona03': 'Zona 03',
    'zona04': 'Zona 04',
    'zona05': 'Zona 05',
    'zona01': 'Zona 01',
    'zona07': 'Zona 07',
    'zona08': 'Zona 08',
    'sanjosepinula': 'San José Pinula',
    'santacatarinapinula': 'Santa Catarina Pinula',
    'fraijanes': 'Fraijanes',
    'zona19': 'Zona 19',
    'mixco1': 'Mixco, Zona 1 a la 4',
    'zona16': 'Zona 16',
    'zona06': 'Zona 06',
    'zona18': 'Zona 18',
    'zona17': 'Zona 17',
    'chinautla': 'Chinautla',
    'zona09': 'Zona 09',
    'zona15': 'Zona 15',
    'zona10': 'Zona 10',
    'zona14': 'Zona 14',
    'zona13': 'Zona 13',
    'zona12': 'Zona 12',
    'zona11': 'Zona 11',
    'zona21': 'Zona 21',
    'villanueva': 'Villanueva',
    'sanmiguelpetapa': 'San Miguel Petapa',
    'villacanales': 'Villa Canales',
    'amatitlan': 'Amatitlán',
    'mixco2': 'Mixco, Zona 5 a la 11'
  };
  

  const { value } = await Swal.fire({
    title: 'COTIZAR ENVIO',
    text: 'INGRESA ZONA DE RECOLECCION',
    input: 'select',
    inputOptions: zonasDestino,
    inputPlaceholder: 'Seleccione una zona',
    showCancelButton: true,
    confirmButtonText: 'Cotizar',
    cancelButtonText: 'Cancelar'
  });

  return value;
}
    isTimestamp(value: any): boolean {
      return value && value.seconds !== undefined && value.toDate instanceof Function;
    }
    todoListo: boolean = false;
    todoListo1: boolean = false;

    mensaje: string = "INCOMPLETO"; // Mensaje predeterminado
    mensaje1: string = "INCOMPLETO"; // Mensaje predeterminado

    // Función para verificar si todo está listo
    verificarListo() {
      if(this.selectedBanco && this.auth.NombreUsuario && this.auth.numero && this.auth.Direccion && this.markerInicio[0] && this.zonainicio)
      {
        this.mensaje1 = 'COMPLETO',
        this.todoListo1 = true;
        
      }
      else{
        this.mensaje1 = 'INCOMPLETO',
        this.todoListo1 = false;
      }
      if(this.nombreFinal && this.numFinal && this.zonafinal && this.dirFinal){
        this.mensaje = 'COMPLETO',
        this.todoListo = true;
      }
      else{
        this.mensaje = 'INCOMPLETO',
        this.todoListo = false;
      }
      
    }    

    isCardVisible: boolean = false;

    toggleCardVisibility(a) {
      this.verificarListo()
      if(a=='recibe'){
        this.mostrarVista(this.vistaFin);
        this.isCardVisible = !this.isCardVisible;
        this.VistaRecoger = false
        this.VistaEnvio = true
      }
      else{
        this.mostrarVista(this.vistaInicio);
        this.isCardVisible = !this.isCardVisible;
        this.VistaRecoger = true
        this.VistaEnvio = false
      }
    }
    public downloadImage(guia): void {             
      const htmlData = document.getElementById('htmlData');
      html2canvas(htmlData).then(canvas => {
        const imgData = canvas.toDataURL('image/jpg');
        const a = document.createElement('a');
        a.href = imgData;
        a.download = `${guia}.jpg`;
        a.click();
      });
    } 
ngOnInit(): void { 


  this.ProductService.inicializarEsVIPObservable(this.auth.IdUsuario)
 this.ProductService.esVIP.subscribe((esVIP: boolean) => {
  this.esVIP = esVIP;
});
  this.obtenerBancos();
  this.verificarListo()
  this.autorizacion()
  this.ingresoPedido();
  // const pageReloaded = localStorage.getItem(this.localStorageKey);
  // if (!pageReloaded) {
  //   localStorage.setItem(this.localStorageKey, 'true');
  //   location.reload(); // Recargar la página
  // } else {
  //   localStorage.removeItem(this.localStorageKey);
  // }
  const numeroAleatorio = Math.floor(1000 + Math.random() * 9000);
  const generateRandomString = (num) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result1 = '';
    for (let i = 0; i < num; i++) {
      result1 += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result1;
  }
this.CDSC = Number(numeroAleatorio)
this.auth.fAvatar();
this.auth.fAvatarE();
this.validador();
}

@ViewChild(GoogleMap, { static: false }) mapa: GoogleMap;


//DATOS DEL PEDIDO
public descuento= 5.00
public cod:number=0;
public total:number = 0;
public producto = "";
public nombreFinal;
public numFinal;
public dirFinal;
public dirInicio;
public numInicio;
public pago=""  ;
public zona;

bancos: any[] = [{alias: 'NO DESEO COD', id: 'NO DESEA COD', nombre: 'NO DESEA COD',numeroCuenta: 'NO DESEA COD', tipoCuenta: 'NO DESEA COD', nombreTitular: 'NO DESEA COD', dpiTitular: 'NO DESEA COD',}];
esVIP: boolean = false;
//DATOS DEL USUARIO REGISTRADO
autorizacion(){
  if (!this.auth || !this.auth.IdUsuario) {
    window.location.reload();
  } else {
    const any = {
      1: this.auth.IdUsuario.slice(0, 2),
      2: this.auth.IdUsuario.slice(-2),
    };
  }
}
extraer: any = {
  1: this.auth.IdUsuario?.slice(0, 2),
  2: this.auth.IdUsuario?.slice(-2),
};
CorreoUsuario = this.auth.CorreoUsuario
RolUsuario = this.auth.RolUsuario
avatar: any;
editarZona = false; 
editarProducto = false;
editarDireccionEnvio = false;
editarDireccionRecoger = false;
editarCobrar = false;    
idproducto:any ;
pedido = {} 

personal = true
empresa = false
VistaEnvio = true
VistaRecoger = false
pedidoEnviado = false;
//MAPA
markerInicio: google.maps.LatLngLiteral[] = [];
markerFin: google.maps.LatLngLiteral[] = [];
lat:number
lng:number 
center = {lat: 14.64072, lng: -90.51327};
iconoInicio= "./../../assets/marker2.png"
iconoFin= "./../../assets/marker2.png"
mInicioOp = {
  draggable: true,
  icon:this.iconoInicio
  };
mFinOp ={
 draggable: true,
 icon: this.iconoFin
}
zoom = 15;
search : string ="";
public searchResults = new Array <any>(); 
display?: google.maps.LatLngLiteral;
options =
  {
    componentRestrictions: { country: "gt" },
    types: [],
    restriction:{
      latLngBounds: this.limitesguatemala,

    },

  gestureHandling: "greedy",
  fullscreenControl: false,
  zoomControl: true,
  scrollwheel: false,
  disableDoubleClickZoom: true,
  mapTypeControl : false,
  clickableIcons : false,
  keyboardShortcuts : false,
  streetViewControl:false,
  
}
options1={
  zoomControl: true,
  componentRestrictions: { country: "gt" },
  types: [],
  restriction:{
    latLngBounds: this.limitesguatemala,

  }
  
}; 


//VARIOS
public VistaSeleccionar= true
public VistaDirecciones = false
public a : string;
public b: string;
sresultado : string
zonadelcliente = [];
guia:string;
minimo:number=1
maximo:number= 9999
area:any;
pquienenvia=false
pquienrecibe=false

//--------->FUNCIONES
ubicacionActual() {
  navigator.geolocation.getCurrentPosition(position => {
    this.center = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    this.zoom = 15;
  });
}
selectDocument(documentId: string) {
  this.selectedDocumentId = documentId;
}
async concat(): Promise<void> {
  this.guia = await this.generarNumeroGuia();

  const barcodeData = this.guia;
  const barcodeElement = document.getElementById('barcode') as HTMLCanvasElement;

  JsBarcode(barcodeElement, barcodeData, {
    format: "CODE128",
    displayValue: false,
    margin: 0,
    width: 2,
    height: 60
  });

  let barcodeImage: any = barcodeElement.toDataURL("image/png");
  barcodeElement.setAttribute("src", barcodeImage);

  return Promise.resolve();
}
vistaEmpresa(){
  this.personal = false
  this.empresa = true
}
vistaPersonal(){
    this.personal = true
    this.empresa = false
}
Ocultar(){this.pedidoEnviado = true}
validador(){
  this.avatar = localStorage.getItem("PERSONAL")
} 
public Destino()
{
  this.search= ""
  window.scroll({
    top: 1,
    behavior: 'smooth'
  });
  if (navigator.geolocation)
  {
    navigator.geolocation.getCurrentPosition(position =>
    {this.lat= position.coords.latitude;
      this.lng= position.coords.longitude;
      this.center =  {lat:this.lat,lng:this.lng};
      this.display = this.center 
      this.zoom = 15
      this.markerFin[0]={lat:this.lat,lng:this.lng}
    })
  }
}
public origen()
  { 
    window.scroll({
      top: 1,
      behavior: 'smooth'
    });
    this.VistaRecoger = true
    this.search= "";
    if (navigator.geolocation)
    {
      navigator.geolocation.getCurrentPosition(position =>
      {
        this.lat= position.coords.latitude;
        this.lng= position.coords.longitude;
        this.center = {lat:this.lat,lng:this.lng};
        this.display = this.center 
        this.zoom = 15
        this.markerInicio[0]={lat:this.lat,lng:this.lng}
      })
    }
}
searchChanged()
{
    if (!this.search.trim().length) return;
    this.googleAutocomplete.getPlacePredictions({input: this.search},predictions =>
    {
    this.ngZone.run(()=>{this.searchResults=predictions;});
    })
}

async obtenerBancos() {
  try {
    // Obtener el valor de this.auth.IdUsuario
    const idUsuario = await this.auth.IdUsuario;

    // Verificar que idUsuario tenga un valor definido
    if (!idUsuario) {
      return;  
    }
    // Acceder a la colección en Firestore
    const bancosRef = this.afs.collection('Usuarios').doc(idUsuario).collection('BC');
    // Obtener los documentos de la colección
    const querySnapshot = await bancosRef.get().toPromise();

    const bancosObtenidos = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    this.bancos = [...this.bancos, ...bancosObtenidos];
  } catch (error) {
    console.error('Error al obtener bancos:', error);

  }
}


getBankName(bancoId: string): string {
  const banco = this.bancos.find(b => b.id === bancoId);
  return banco ? banco.alias : 'Desconocido';
}
  async GuardarUbicacion(lat: number, lng: number) {
  const options: Record<string, string> = {};
  this.bancos.forEach((banco) => {
    if (banco.alias) {
      options[banco.id] = banco.alias;
    }
  });
  const stepBancos: SweetAlertOptions = {
    title: 'Banco a liquidar',
    input: 'select',
    inputValue: '',
    inputOptions: {
      ...options,
    },
    inputPlaceholder: 'Selecciona el banco',
    inputValidator: (value) => {
      if (!value) {
        return 'Este campo es obligatorio';
      }
      return null;
    },
  };


  const result = await Swal.mixin({
    input: 'text',
    confirmButtonText: 'Guardar',
    showCancelButton: true,
    progressSteps: ['1', '2', '3', '4'],
  }).queue([
    {
      title: 'NOMBRE QUIEN ENVIA',
      inputValue: this.auth.NombreUsuario,
      inputPlaceholder: 'Ej. Mi Casa'
    },
    {
      title: 'DIRECCION',
      inputValue: this.auth.Direccion,
      inputPlaceholder: 'Ej. 9av. 15-50 ZONA 10 GUATEMALA'
    },
    {
      title: 'NUMERO DE TELÉFONO',
      inputValue:  this.auth.numero,
      inputPlaceholder: 'Ej. 59663352',
    },
    {
      title:'ZONA DE RECOLECCION',
      inputValue: `${this.zonainicio}` ,
      input: 'select',
      position: 'center',
      allowEnterKey: false,
      showLoaderOnConfirm: true,
      allowOutsideClick:false,
      inputOptions: {
        'zona01': 'ZONA 01',
        'zona02': 'ZONA 02',
        'zona03': 'ZONA 03',
        'zona04': 'ZONA 04',
        'zona05': 'ZONA 05',
        'zona06': 'ZONA 06',
        'zona07': 'ZONA 07',
        'zona08': 'ZONA 08',
        'zona09': 'ZONA 09',
        'zona10': 'ZONA 10',
        'zona11': 'ZONA 11',
        'zona12': 'ZONA 12',
        'zona13': 'ZONA 13',
        'zona14': 'ZONA 14',
        'zona15': 'ZONA 15',
        'zona16': 'ZONA 16',
        'zona17': 'ZONA 17',
        'zona18': 'ZONA 18',
        'zona19': 'ZONA 19',
        'zona21': 'ZONA 21',
        'mixco1': 'MIXCO (zonas: 1, 2, 3, 4)',
        'mixco2': 'MIXCO (zonas: 5, 6, 7, 8, 9, 10, 11)',
        'villanueva': 'VILLA NUEVA',
        'sanmiguelpetapa': 'SAN MIGUEL PETAPA',
        'villacanales': 'VILLA CANALES',
        'amatitlan': 'AMATITLAN',
        'fraijanes': 'FRAIJANES',
        'sanjosepinula': 'SAN JOSE PINULA',
        'santacatarinapinula': 'SANTA CATARINA PINULA',
        'chinautla': 'CHINAUTLA'
      },
      inputValidator: (value) => {
        if (!value) {
          return 'Este campo es obligatorio';
        }
        return null;
      },
    }, 
    stepBancos,
  ]).then((result) => {
    if (result.dismiss) {
      return;
    }
    const nombreUbicacion = result.value[0];
    const ubicacionMapa = result.value[1];
    const numTelefono = result.value[2];
    const zonainicio = result.value[3];
    const banco = result.value[4];
    // Valida el número de teléfono
    if (!numTelefono || isNaN(Number(numTelefono)) || numTelefono.length !== 8) {
      Swal.fire({
        title: 'Número de Teléfono Inválido',
        text: 'Por favor, ingresa un número de teléfono válido de 8 dígitos.',
        icon: 'error'
      });
      return;
    }

    const ubicacionesRef = this.afs.collection('Usuarios').doc(this.auth.IdUsuario).collection('UBICACIONES');
    ubicacionesRef.add({
      Nombre: nombreUbicacion,
      Coordenadas: { lat: lat, lng: lng },
      Direccion: ubicacionMapa,
      Numero:numTelefono,
      Zona: zonainicio,
      Banco: banco

    }).then((docRef) => {
      docRef.update({ Referencia: docRef.id });
    });



    // Aquí puedes guardar la ubicación en la base de datos con los datos proporcionados.
    // Puedes utilizar las variables lat, lng, nombreUbicación, ubicaciónMapa, numTelefono para guardar estos datos.
  });
}


selectedBanco: string = '';
bancoSeleccionado: boolean = false;

onBancoSelected(bancoId: string) {
  this.selectedBanco = bancoId;
  this.bancoSeleccionado = true;
}


public SeleccionarUbicacion(){
this.VistaDirecciones = !this.VistaDirecciones
}
public handleAddressChange(address: Address, isEnvio: boolean) {   
  const marker = {
    lat: address.geometry.location.lat(),
    lng: address.geometry.location.lng()
  };
  
  if (isEnvio) {
    this.markerFin[0] = marker;
    this.center = marker;
  } else {
    this.markerInicio[0] = marker;
    this.center = marker;
  }
  
  this.zoom = 14;
  this.search = "";
}
actualizarPosicionMarcador() {
  this.center = {
    lat: this.mapa.getCenter().lat(),
    lng: this.mapa.getCenter().lng()
  };

  // Actualiza la posición del marcador en el centro del mapa
  this.markerInicio[0] = this.center;
}
async Seleccionada(banco, coordenadas, Direccion, Nombre, Numero?, Zona?) {
  try {
    // Asignación de valores de forma asíncrona
    this.zonainicio = Zona;
    this.selectedBanco = banco;
    this.lat = coordenadas.lat;
    this.lng = coordenadas.lng;
    this.markerInicio[0] = { lat: this.lat, lng: this.lng };
    this.auth.NombreUsuario = Nombre;
    this.auth.Direccion = Direccion;
    this.auth.numero = Numero;

    // Esperar a que todos los valores se asignen antes de continuar
    await Promise.all([
      this.zonainicio,
      this.selectedBanco,
      this.lat,
      this.lng,
      this.markerInicio[0],
      this.auth.NombreUsuario,
      this.auth.Direccion,
      this.auth.numero
    ]);

    // Después de asegurarte de que todos los valores están asignados, continúa con el resto de tu lógica
    this.VistaDirecciones = false;
    this.center = this.markerInicio[0];
    this.verificarMontos(this.zonainicio, this.zonafinal);
  } catch (error) {
    console.error('Error en Seleccionada:', error);
    // Manejar el error según sea necesario
  }
}

public editarguardadas(ref){
  Swal.fire({
    title: 'Selecciona una Opcion',
    input: 'select',
    inputOptions: {
      1:'NOMBRE',
      2: 'NUMERO', 
      3: 'DIRECCION',
      4: 'ZONA'

    },
    showCancelButton: true,
    confirmButtonText: 'EDITAR',
    showLoaderOnConfirm: true,
  }).then((result) => {
    this.verificarMontos(this.zonainicio,this.zonafinal)


      if (result.value == 3) {
        Swal.fire({
          title:'DIRECCION',
          input: 'textarea',
          position: 'center',
          allowEnterKey: false,
          showLoaderOnConfirm: true,
          scrollbarPadding: false,
          allowOutsideClick:false
           }).then((result) => {
       return  this.afs.collection('Usuarios').doc(this.auth.IdUsuario).collection('UBICACIONES').doc(ref).update({
        Direccion: result.value
       })
        })
      }
      if (result.value == 1) {
        Swal.fire({
          title:'NOMBRE',
          inputValue: this.auth.NombreUsuario, 
          input: 'text',
          position: 'center',
          allowEnterKey: false,
          showLoaderOnConfirm: true,
          scrollbarPadding: false,
          allowOutsideClick:false
           }).then((result) => {
            return  this.afs.collection('Usuarios').doc(this.auth.IdUsuario).collection('UBICACIONES').doc(ref).update({
              Nombre: result.value
             })
              })
      }
      if (result.value == 2) {
        Swal.fire({
          title:'NUMERO',
          inputValue: this.auth.numero,
          input: 'number',
          position: 'center',
          allowEnterKey: false,
          showLoaderOnConfirm: true,
          scrollbarPadding: false,
          allowOutsideClick:false,
          inputValidator: (value) => {
            return new Promise((resolve : any) => {
              const num = parseInt(value);
              const isValidNumber = Number.isInteger(num) && value.toString().length === 8;
              
              if (isValidNumber) {
              resolve()   
              } else {
                resolve('El número debe ser entero y tener 8 dígitos');
              }
            });
          },
           }).then((result) => {
            return  this.afs.collection('Usuarios').doc(this.auth.IdUsuario).collection('UBICACIONES').doc(ref).update({
              Numero: result.value
             })
        })
      }
      if(result.value == 4){
        Swal.fire({
          title:'ZONA DE RECOLECCION',
          inputValue: `${this.zonainicio}` ,
          input: 'select',
          position: 'center',
          allowEnterKey: false,
          showLoaderOnConfirm: true,
          allowOutsideClick:false,
          inputOptions: {
            'zona01': 'ZONA 01',
            'zona02': 'ZONA 02',
            'zona03': 'ZONA 03',
            'zona04': 'ZONA 04',
            'zona05': 'ZONA 05',
            'zona06': 'ZONA 06',
            'zona07': 'ZONA 07',
            'zona08': 'ZONA 08',
            'zona09': 'ZONA 09',
            'zona10': 'ZONA 10',
            'zona11': 'ZONA 11',
            'zona12': 'ZONA 12',
            'zona13': 'ZONA 13',
            'zona14': 'ZONA 14',
            'zona15': 'ZONA 15',
            'zona16': 'ZONA 16',
            'zona17': 'ZONA 17',
            'zona18': 'ZONA 18',
            'zona19': 'ZONA 19',
            'zona21': 'ZONA 21',
            'mixco1': 'MIXCO (zonas: 1, 2, 3, 4)',
            'mixco2': 'MIXCO (zonas: 5, 6, 7, 8, 9, 10, 11)',
            'villanueva': 'VILLA NUEVA',
            'sanmiguelpetapa': 'SAN MIGUEL PETAPA',
            'villacanales': 'VILLA CANALES',
            'amatitlan': 'AMATITLAN',
            'fraijanes': 'FRAIJANES',
            'sanjosepinula': 'SAN JOSE PINULA',
            'santacatarinapinula': 'SANTA CATARINA PINULA',
            'chinautla': 'CHINAUTLA'
          },
          inputValidator: (value) => {
            if (!value) {
              return 'Este campo es obligatorio';
            }
            else {
              this.afs.collection('Usuarios').doc(this.auth.IdUsuario).collection('UBICACIONES').doc(ref).update({
                Zona: value
               })
  
            }
          }
           })
      }
if(this.empresa){
  if (result.value == 3) {
    Swal.fire({
      title:'EDITAR DIRECCION DE QUIEN ENVIA',
      inputValue: `${this.auth.Direccion}` ,
      input: 'text',
      position: 'center',
      allowEnterKey: false,
      showLoaderOnConfirm: true,
      scrollbarPadding: false,
      allowOutsideClick:false
       }).then((result) => {
   return  this.auth.Direccion = result.value
    })
  }
  if (result.value == 1) {
    Swal.fire({
      title:'EDITAR NOMBRE DE TU NEGOCIO',
      inputValue: this.auth.AvatarE.Nombre,
      input: 'text',
      position: 'center',
      allowEnterKey: false,
      showLoaderOnConfirm: true,
      scrollbarPadding: false,
      allowOutsideClick:false
       }).then((result) => {
   return  this.auth.AvatarE.Nombre = result.value
    })
  }
  if (result.value == 2) {
    Swal.fire({
      title:'EDITAR NUMERO DE TU NEGOCIO',
      inputValue: this.auth.AvatarE.Numero,
      input: 'number',
      position: 'center',
      allowEnterKey: false,
      showLoaderOnConfirm: true,
      scrollbarPadding: false,
      allowOutsideClick:false
       }).then((result) => {
  return this.auth.AvatarE.Numero = result.value
    })
  }

}

  }) 
}
public ELIMINARUBICACION (ref:any){
  Swal.fire({
    title:'DESEAS ELIMINAR ESTA UBICACION?',
  }).then((v)=>{
    if(v.dismiss)
    return
    else{
      this.afs.collection('Usuarios').doc(this.auth.IdUsuario).collection('UBICACIONES').doc(ref).delete()

    }
  })
}
public toggleVista() {
  if (this.VistaEnvio) {
    this.mostrarVista(this.vistaInicio);
  } else {
    this.mostrarVista(this.vistaFin);
  }
  

}
private mostrarVista(vista: Function) {
  window.scroll({
    top: 1, 
    behavior: 'smooth'
  });
  vista.call(this);
}
public vistaInicio() {
  this.verificarListo()
  this.VistaRecoger = false;
  this.center = this.markerInicio[0];
  this.mFinOp = {
    draggable: true, 
    icon: this.iconoFin
  };
  this.mInicioOp = {
    draggable: false, 
    icon: this.iconoInicio
  };
  this.VistaEnvio = false;
  this.VistaRecoger = true;
}
public vistaFin() {
  this.verificarListo(),
  [this.center] = this.markerFin;
  this.mInicioOp = {
    draggable: true, 
    icon: this.iconoInicio
  };
  this.mFinOp = {
    draggable: false, 
    icon: this.iconoFin
  };
  this.VistaRecoger = false;
  this.VistaEnvio = true;
}
public get placeholder(): string {
  return this.VistaEnvio ? 'A DONDE ENVIAREMOS' : 'EN DONDE RECOGEREMOS';
}
arrastrarMi() {
  if (this.VistaEnvio) {
    this.mInicioOp = {
      draggable: true,
      icon: "./../../assets/marker.png"
    };
    this.mFinOp = {
      draggable: false,
      icon: "./../../assets/marker2.png"
    };
  } else {
    this.mFinOp = {
      draggable: true,
      icon: "./../../assets/marker.png"
    };
    this.mInicioOp = {
      draggable: false,
      icon: "./../../assets/marker2.png"
    };
  }
}
arrastrarM(event: google.maps.MouseEvent) {

  this.mInicioOp = {
    draggable: true,
    icon: "./../../assets/marker2.png"
  };
  this.mFinOp = {
    draggable: false,
    icon: "./../../assets/marker2.png"
  };
  this.zoom = 15;
  this.markerFin[0] = event.latLng.toJSON();
  this.center = event.latLng.toJSON();
}
arrastrarM2(event: google.maps.MouseEvent) {

  this.mFinOp = {
    draggable: true,
    icon: "./../../assets/marker2.png"
  };
  this.mInicioOp = {
    draggable: false,
    icon: "./../../assets/marker2.png"
  };
  this.zoom = 15;
  this.markerInicio[0] = event.latLng.toJSON();
  this.center = event.latLng.toJSON();
}
agregarMarker(event: google.maps.MouseEvent) {
  const marker = event.latLng.toJSON();
  if (this.VistaEnvio) {

    this.markerFin[0] = marker;
    this.mInicioOp = {
      draggable: true,
      icon: "./../../assets/marker2.png"
    };
    this.mFinOp = {
      draggable: false,
      icon: "./../../assets/marker2.png"
    };
    this.verificarListo()
  } else {
    this.markerInicio[0] = marker;
    this.mFinOp = {
      draggable: true,
      icon: "./../../assets/marker2.png"
    };
    this.mInicioOp = {
      draggable: false,
      icon: "./../../assets/marker2.png"
    };
    this.verificarListo()
  }
  this.center = marker
}
generarNumeroGuia(): Promise<string> {
  const guiasRef = this.afs.collection('CONTADOR').doc('contador');

  return this.afs.firestore.runTransaction(async (transaction) => {
    const doc = await transaction.get(guiasRef.ref);

    if (!doc.exists) {
      // Si no hay datos existentes, inicializa el contador en 1
      transaction.set(guiasRef.ref, { contador: 1 });
      return 'VR0001';
    }

    // Incrementa el contador existente
    const contador = doc.data().contador + 1;
    transaction.update(guiasRef.ref, { contador });

    // Genera el número de guía con el formato "VR0000"
    const numeroGuia = `VR${contador.toString().padStart(4, '0')}`;

    // Devuelve el número de guía generado
    return numeroGuia;
  })
  .then((numeroGuia) => {
    return numeroGuia;
  })
  .catch((error) => {
    console.error('Error al generar el número de guía:', error);

    // Genera un número aleatorio de 4 dígitos
    const numeroAleatorio = Math.floor(1000 + Math.random() * 9000);

    // Genera un texto aleatorio de 4 caracteres
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let textoAleatorio = '';
    for (let i = 0; i < 4; i++) {
      textoAleatorio += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }

    // Genera el número de guía alternativo combinando el texto y número aleatorio con el formato "VR0000"
    const numeroGuiaAlternativo = `VR${textoAleatorio}${numeroAleatorio.toString().padStart(4, '0')}`;
    return numeroGuiaAlternativo;
  });
}

async enviarPedido() { 
  document.getElementById('spinner').style.display = 'block';
  await this.concat()
  try {
const selectedOption = this.zonafinal;
const usuarioUID = this.auth.IdUsuario;
var PAGOCONDUCTOR: Number = 0
var Credito: Number =0
if(this.esVIP){
PAGOCONDUCTOR = this.Precio1 - 5
Credito = this.Precio1
}
else if (!this.esVIP){
PAGOCONDUCTOR= this.Precio1 - 10
Credito = 0
}
    const codValue = this.cod ? Number(this.cod) : 0;
    const total = Number(this.Precio1) + Number(codValue);
    const avatar = this.auth.ap;
    const nombre = this.auth.NombreUsuario;
    const numero = this.auth.numero;
    const direccion = this.auth.Direccion;
    const markerFin = this.markerFin[0] ? this.analizar(this.ProductService.dist(this.markerFin[0])) : 'NO BRINDA COORDENADAS';
    const zonafinal = this.zonafinal
    const zonainicio = this.zonainicio
    const mInicioOp = {
      draggable: false,
      icon: this.iconoInicio
    };
    const mFinOp = {
      draggable: false,
      icon: this.iconoFin
    };

    const pedido = {
      Credito: Credito,
      Banco: this.selectedBanco,
      PAGOCONDUCTOR:PAGOCONDUCTOR,
      Cod: Number(codValue),
      Conductor: 'Sin Asignar',
      CDSC: this.CDSC,
      Estado: 'SOLICITADO',
      AVATAR: avatar,
      FECHA: this.fecha,
      Fecha_Estado: this.fecha,
      GUIA: this.guia,
      NOMBRE_INICIO: nombre,
      NUM_INICIO: numero,
      DIR_INICIO: direccion,
      Disponible: true,
      NOMBRE_FINAL: this.nombreFinal,
      NUM_FINAL: this.numFinal,
      DESTINO: this.dirFinal,
      PRODUCTO: this.producto,
      COBRAR: this.pago,
      PRECIO: Number(this.Precio1),
      USUARIO: this.auth.IdUsuario,
      ZONAINICIO: zonainicio,
      ZONAFINAL: zonafinal,
      CodigoValidado: false,
      COORDENADAS_INICIO: this.markerInicio[0] ? this.markerInicio[0] : 'NO BRINDA COORDENADAS',
      COORDENADAS_DESTINO: this.markerFin[0] ? this.markerFin[0] : 'NO BRINDA COORDENADAS',
      LiquidacionProveedor: false 
    };
    this.total = total;
    this.VistaRecoger = false;
    this.VistaEnvio = false;
    this.sresultado = '';
    this.area = '';
    this.pedido = pedido;
    this.area = pedido.ZONAINICIO;
    this.sresultado = this.analizar(this.area);

 const mensajeGrupo = `
 NUEVA GUIA GENERADA 
 DESDE: ${pedido.ZONAINICIO} 
 PARA: ${pedido.ZONAFINAL} 
`

    await this.ProductService.zona2(pedido, this.guia),

    await this.enviarMensajeTelegram(mensajeGrupo,this.grupo),
// Mostrar el contenido después de que se complete la función
document.getElementById('spinner').style.display = 'none';
document.getElementById('contenido').style.display = 'block';
  } catch (error) {
    console.error('Error al enviar el pedido:', error);

    // Realizar acciones en caso de error
  }
}
ingresoPedido() { 
  this.cod= 0
  this.UBICACIONES$ = this.afs.collection('Usuarios').doc(this.auth.IdUsuario).collection('UBICACIONES').valueChanges()
  this.UBICACIONES$.pipe(
    map((ubicaciones: any[]) => {
      // Filtra la ubicación con "PRINCIPAL" igual a true
      const ubicacionPrincipal = ubicaciones.find(ubicacion => ubicacion.PRINCIPAL === true);
      return ubicacionPrincipal;
    })
  ).subscribe(ubicacionPrincipal => {
    if (ubicacionPrincipal) {
      // Accede a los campos específicos de la ubicación principal
    this.cod= 0
    const referencia = ubicacionPrincipal.Referencia;
    const lat = ubicacionPrincipal.Coordenadas.lat;
    const lng = ubicacionPrincipal.Coordenadas.lng;
    const Numero = ubicacionPrincipal.Numero;
    const Direccion = ubicacionPrincipal.Direccion;
    const Zona = ubicacionPrincipal.Zona;
    const Nombre = ubicacionPrincipal.Nombre;
    this.selectedBanco = ubicacionPrincipal.Banco
    this.lat= lat;
    this.lng= lng;
    this.markerInicio[0] = {lat:lat,lng:lng}
    this.auth.NombreUsuario = Nombre
    this.auth.Direccion = Direccion
    this.auth.numero = Numero
    this.VistaDirecciones = false
    this.center = this.markerInicio[0];
    this.zonainicio = Zona
  this.verificarListo()

    } else {

    }
  });

  this.area = this.zona;
  
  Swal.mixin({
    input: 'text',
    position: 'center',
    confirmButtonText: 'Siguiente',
    showCancelButton: true,
    allowEnterKey: false,
    scrollbarPadding: false,
    allowOutsideClick:false
  }).queue([ 
    {
      title: 'NOMBRE DE QUIEN RECIBE',
      inputPlaceholder: 'Ej. JOSE AREVALO GARCIA',
      inputValidator: (value) => {
        if (!value) {
          return 'Este campo es obligatorio';
        }
        return null;
      },
    },
    {
      footer: '<strong>tip: Asegurate que el numero de la persona que recibe este activo.</strong>',
  title: 'NUMERO QUIEN RECIBE',
  input: 'number',
  inputValidator: (value) => {
    return new Promise((resolve: any) => {
      const num = parseInt(value);
      const isValidNumber = Number.isInteger(num) && value.toString().length === 8;

      if (isValidNumber) {
        resolve();
      } else {
        resolve('El número debe ser entero y tener 8 dígitos');
      }
    });
  },
  onOpen: () => {
    setTimeout(() => {
      const inputElement = Swal.getInput();
      if (inputElement) {
        inputElement.addEventListener('wheel', (event) => {
          event.preventDefault();
        });
      }
    }, 0);
  },
      inputPlaceholder: 'Ej. 59663352'
    }    ,
    {
      footer: '<strong>tip: Selecciona la Zona de Entrega*</strong>',
      title: 'En que Zona entregaremos:',
      input: 'select',
      inputOptions: {
        'zona01': 'ZONA 01',
        'zona02': 'ZONA 02',
        'zona03': 'ZONA 03',
        'zona04': 'ZONA 04',
        'zona05': 'ZONA 05',
        'zona06': 'ZONA 06',
        'zona07': 'ZONA 07',
        'zona08': 'ZONA 08',
        'zona09': 'ZONA 09',
        'zona10': 'ZONA 10',
        'zona11': 'ZONA 11',
        'zona12': 'ZONA 12',
        'zona13': 'ZONA 13',
        'zona14': 'ZONA 14',
        'zona15': 'ZONA 15',
        'zona16': 'ZONA 16',
        'zona17': 'ZONA 17',
        'zona18': 'ZONA 18',
        'zona19': 'ZONA 19',
        'zona21': 'ZONA 21',
        'mixco1': 'MIXCO (zonas: 1, 2, 3, 4)',
        'mixco2': 'MIXCO (zonas: 5, 6, 7, 8, 9, 10, 11)',
        'villanueva': 'VILLA NUEVA',
        'sanmiguelpetapa': 'SAN MIGUEL PETAPA',
        'villacanales': 'VILLA CANALES',
        'amatitlan': 'AMATITLAN',
        'fraijanes': 'FRAIJANES',
        'sanjosepinula': 'SAN JOSE PINULA',
        'santacatarinapinula': 'SANTA CATARINA PINULA',
        'chinautla': 'CHINAUTLA'
      },

      inputValidator: (value) => {
        if (!value) {
          return 'Este campo es obligatorio';
        }
        this.zonafinal = value
      }
    }, 
    {
      footer: '<strong>tip: Se lo mas preciso posible,agrega cualquier punto importante (codigos de acceso etc.), de preferencia ubica en el mapa el lugar. </strong>',
      title: 'DIRECCION QUIEN RECIBE:',
      input: 'textarea',
      inputValidator: (value) => {
        if (!value) {
          return 'Este campo es obligatorio';
        }
        return null;
      },
      inputPlaceholder: 'Ej. 4av. 5-16 zona 1 de mixco'
    },
    {
      footer: '<strong>tip: COLOCA 0 SI NO COBRAREMOS, No sumes el precio de envío, solamente de tu producto (el sistema suma el envío al final) </strong>',
title: 'CUANTO COBRAREMOS DE TU PRODUCTO',
input: 'number',
inputAttributes: {
  step: '0.01', // Esto permite números decimales con dos decimales
  min: '0', // Esto evita números negativos
},
inputValidator: (value) => {
  if (!value) {
    return 'Este campo es obligatorio';
  } else if (Number(value) < 0) {
    return 'no puede ser negativo';
  }
  return null;
},
inputPlaceholder: 'Ej. 50.00',
onOpen: () => {
  setTimeout(() => {
    const inputElement = Swal.getInput();
    if (inputElement) {
      inputElement.addEventListener('wheel', (event) => {
        event.preventDefault();
      });
    }
  }, 0);
}
},
    {
      footer: '<strong>tip: De momento el pago solo es en efectivo</strong>',
      title: 'QUIEN PAGARA EL ENVIO:',
      input: 'radio',
      inputValidator: (value) => {
        if (!value) {
          return 'Este campo es obligatorio';
        }
        return null;
      },
      inputOptions: {
        1: 'QUIEN ENVIA',
        2: 'QUIEN RECIBE'
      }
    },
    {
      footer: '<strong>tip: Evita enviar liquidos, comida, objetos de valor... revisa nuestras politicas</strong>',
      title: 'DESCRIBE LO QUE ENVIAS',
      inputValidator: (value) => {
        if (!value) {
          return 'Este campo es obligatorio';
        }
        return null;
      },
      inputPlaceholder: 'Ej. Una caja de zapatos'
    }
  ]).then(async (result) => {

    if (result.value !== undefined){
      this.cod = result.value[4];
      this.zonafinal = result.value[2];
      this.pago = result.value[5];
  
      // this.Precio1 = this.ProductService.precio(this.zonafinal,this.auth.IdUsuario);
     
      if(result.dismiss) {
        return;
      }
      await  this.verificarMontos(this.zonainicio,this.zonafinal)
      if(result.value[5] =="1"){
        this.pquienenvia = true
        this.pquienrecibe = false
        this.total = Number(this.cod)
      }
      else if (result.value[5] =="2"){
        this.pquienenvia = false
        this.pquienrecibe = true
        this.total = Number(this.cod) + Number(this.Precio1);
      } 
      this.nombreFinal = result.value[0];
      this.numFinal = result.value[1];
      this.dirFinal = result.value[3];
      this.producto = result.value[6];
      this.verificarListo()  }
    else {
      console.error('no está definido.');
    }
      })
        

} 


agregarBanco() {
  const bancosDisponibles = [
    'Banco Industrial',
    'Banco G&T Continental',
    'Banco de Desarrollo Rural (Banrural)',
    'Banco Agromercantil (BAM)',
    'Banco Azteca',
    'Banco Promérica',
    'Banco Bantrab',
    'Banco Inmobiliario',
    'Banco de Antigua',
    'Banco Citi'
  ];
  const tipocuenta = [
    'Ahorro',
    'Monetaria',

  ];
   // Agregar una opción vacía al inicio
   const emptyOption = '<option value=""></option>';

  const options = emptyOption + bancosDisponibles.map(banco => `<option value="${banco}">${banco}</option>`).join('');
  const options1 =emptyOption + tipocuenta.map(banco1 => `<option value="${banco1}">${banco1}</option>`).join('');
  Swal.fire({
    title: 'Agregar Cuenta',
    html: `
    <p class="m-0" style="text-align: left; font-size: 14px;" >BANCO</p>
    <select id="nombre" class="swal2-select mt-1" style="width: 100%;">
      ${options} 
    </select>
    <p class="m-0" style="text-align: left; font-size: 14px;" >ALIAS</p>
    <input id="alias" class="swal2-input mt-1" placeholder="Sobrenombre" style="width: 100%;">
    <p class="m-0" style="text-align: left; font-size: 14px;" >TIPO DE CUENTA</p>
    <select id="tipoCuenta" class="swal2-select mt-1" style="width: 100%;">
      ${options1}
    </select>
    <p class="m-0" style="text-align: left; font-size: 14px;" >NUMERO DE CUENTA</p>
    <input id="numeroCuenta" class="swal2-input mt-1" placeholder="Número de Cuenta" pattern="[0-9]*" style="width: 100%;">
    <p class="m-0" style="text-align: left; font-size: 14px;" >NOMBRE DEL TITULAR</p>
    <input id="nombreTitular" class="swal2-input mt-1" placeholder="Nombre del Titular" style="width: 100%;">
    <p class="m-0" style="text-align: left; font-size: 14px;" >DPI DEL TITULAR</p>
    <input id="dpiTitular" class="swal2-input mt-1" placeholder="DPI del Titular" style="width: 100%;">
  `,
  onBeforeOpen: () => {
    // Limpiar los valores de nombre y tipoCuenta al inicio
    const nombreSelect = document.getElementById('nombre') as HTMLSelectElement;
    const tipoCuentaSelect = document.getElementById('tipoCuenta') as HTMLSelectElement;

    nombreSelect.value = '';
    tipoCuentaSelect.value = '';
  },
    focusConfirm: false,
    showCancelButton: true,
    confirmButtonText: 'Agregar',
    showCloseButton: true,
    preConfirm: () => {
      const alias = (<HTMLInputElement>document.getElementById('alias')).value;
      const nombre = (<HTMLInputElement>document.getElementById('nombre')).value;
      const numeroCuenta = (<HTMLInputElement>document.getElementById('numeroCuenta')).value;
      const tipoCuenta = (<HTMLInputElement>document.getElementById('tipoCuenta')).value;
      const nombreTitular = (<HTMLInputElement>document.getElementById('nombreTitular')).value;
      const dpiTitular = (<HTMLInputElement>document.getElementById('dpiTitular')).value;

      // Validar si todos los campos están llenos
      if (!alias|| !nombre || !numeroCuenta || !tipoCuenta || !nombreTitular || !dpiTitular) {
        Swal.showValidationMessage('Todos los campos son obligatorios');
        return false;
      }

      // Lógica para agregar a Firebase con el ID del documento
      const id = this.afs.createId(); // Generar un ID único
      const docRef = this.afs.collection('Usuarios').doc(this.auth.IdUsuario).collection('BC').doc(id);

      docRef.set({
        alias,
        id,
        nombre,
        numeroCuenta,
        tipoCuenta,
        nombreTitular,
        dpiTitular,
      });
      this.obtenerBancos()
      return true;
    },
  });

}

analizar (a){
  if (typeof a === 'string' && a.startsWith('c')) {
    return a.substring(1)
  }
  return String(a)
}
recargarPagina() {
  // Recarga la página
  location.reload();
}
prueba()
  { Swal.fire({
    title:'INGRESAR DATOS',
    input: 'text',
    position: 'center',
    allowEnterKey: false,
    showLoaderOnConfirm: true,
    scrollbarPadding: false,
    allowOutsideClick:false
    }).then((result) => {             
      if(this.editarProducto){
      this.producto=result.value
      this.editarProducto= false    
      }
      if(this.editarDireccionEnvio){
      this.dirFinal=result.value
      this.VistaRecoger = false
      this.VistaEnvio = true
      this.editarDireccionEnvio= false    
      }
      if(this.editarDireccionRecoger){
        this.auth.Direccion =result.value
      this.VistaRecoger = true
      this.VistaEnvio = false
      this.editarDireccionRecoger= false    
      }
      if(this.editarCobrar){
      this.pago=result.value
      this.editarCobrar= false    
      }    
    }
    )
}
async editarInicio() { 
  Swal.fire({
    footer: '<strong>tip: Los datos que edites solo se veran reflejados en este pedido</strong>',
    title: 'Selecciona una Opcion',
    input: 'select',
    inputOptions: {
      1:'NOMBRE',
      2: 'NUMERO', 
      3: 'DIRECCION',
      4: 'ZONA'
    },
    showCancelButton: true,
    confirmButtonText: 'EDITAR',
    showLoaderOnConfirm: true,
  }).then((result) => {
    this.verificarMontos(this.zonainicio,this.zonafinal)

    if(this.personal){
      if (result.value == 3) {
        Swal.fire({
          title:'EDITAR DIRECCION DE QUIEN ENVIA',
          inputValue: `${this.auth.Direccion}` ,
          input: 'textarea',
          position: 'center',
          allowEnterKey: false,
          showLoaderOnConfirm: true,
          scrollbarPadding: false,
          allowOutsideClick:false
           }).then((result) => {
       return  this.auth.Direccion = result.value
        })
      }
      if (result.value == 1) {
        Swal.fire({
          title:'EDITAR NOMBRE DE QUIEN ENVIA',
          inputValue: this.auth.NombreUsuario, 
          input: 'text',
          position: 'center',
          allowEnterKey: false,
          showLoaderOnConfirm: true,
          scrollbarPadding: false,
          allowOutsideClick:false
           }).then((result) => {
       return  this.auth.NombreUsuario = result.value
        })
      }
      if (result.value == 2) {
        Swal.fire({
          title:'EDITAR NUMERO DE QUIEN ENVIA',
          inputValue: this.auth.numero,
          input: 'number',
          position: 'center',
          allowEnterKey: false,
          showLoaderOnConfirm: true,
          scrollbarPadding: false,
          allowOutsideClick:false,
          inputValidator: (value) => {
            return new Promise((resolve : any) => {
              const num = parseInt(value);
              const isValidNumber = Number.isInteger(num) && value.toString().length === 8;
              
              if (isValidNumber) {
              resolve()   
              } else {
                resolve('El número debe ser entero y tener 8 dígitos');
              }
            });
          },
           }).then((result) => {
      return this.auth.numero = result.value
        })
      }
      if(result.value == 4){
        Swal.fire({
          title:'ZONA DE RECOLECCION',
          inputValue: `${this.zonainicio}` ,
          input: 'select',
          position: 'center',
          allowEnterKey: false,
          showLoaderOnConfirm: true,
          allowOutsideClick:false,
          inputOptions: {
            'zona01': 'ZONA 01',
            'zona02': 'ZONA 02',
            'zona03': 'ZONA 03',
            'zona04': 'ZONA 04',
            'zona05': 'ZONA 05',
            'zona06': 'ZONA 06',
            'zona07': 'ZONA 07',
            'zona08': 'ZONA 08',
            'zona09': 'ZONA 09',
            'zona10': 'ZONA 10',
            'zona11': 'ZONA 11',
            'zona12': 'ZONA 12',
            'zona13': 'ZONA 13',
            'zona14': 'ZONA 14',
            'zona15': 'ZONA 15',
            'zona16': 'ZONA 16',
            'zona17': 'ZONA 17',
            'zona18': 'ZONA 18',
            'zona19': 'ZONA 19',
            'zona21': 'ZONA 21',
            'mixco1': 'MIXCO (zonas: 1, 2, 3, 4)',
            'mixco2': 'MIXCO (zonas: 5, 6, 7, 8, 9, 10, 11)',
            'villanueva': 'VILLA NUEVA',
            'sanmiguelpetapa': 'SAN MIGUEL PETAPA',
            'villacanales': 'VILLA CANALES',
            'amatitlan': 'AMATITLAN',
            'fraijanes': 'FRAIJANES',
            'sanjosepinula': 'SAN JOSE PINULA',
            'santacatarinapinula': 'SANTA CATARINA PINULA',
            'chinautla': 'CHINAUTLA'
          },
          inputValidator: async (value) => {
            if (!value) {
              return 'Este campo es obligatorio';
            }
            this.zonainicio = value
            await  this.verificarMontos(value,this.zonafinal)
            if( this.pquienenvia){
              this.total = this.cod
             }
            else if( this.pquienrecibe){
              this.total = Number(this.cod) + Number(this.Precio1)
             }
          }
           })
      }

    }

if(this.empresa){
  if (result.value == 3) {
    Swal.fire({
      title:'EDITAR DIRECCION DE QUIEN ENVIA',
      inputValue: `${this.auth.Direccion}` ,
      input: 'text',
      position: 'center',
      allowEnterKey: false,
      showLoaderOnConfirm: true,
      scrollbarPadding: false,
      allowOutsideClick:false
       }).then((result) => {
   return  this.auth.Direccion = result.value
    })
  }
  if (result.value == 1) {
    Swal.fire({
      title:'EDITAR NOMBRE DE TU NEGOCIO',
      inputValue: this.auth.AvatarE.Nombre,
      input: 'text',
      position: 'center',
      allowEnterKey: false,
      showLoaderOnConfirm: true,
      scrollbarPadding: false,
      allowOutsideClick:false
       }).then((result) => {
   return  this.auth.AvatarE.Nombre = result.value
    })
  }
  if (result.value == 2) {
    Swal.fire({
      title:'EDITAR NUMERO DE TU NEGOCIO',
      inputValue: this.auth.AvatarE.Numero,
      input: 'number',
      position: 'center',
      allowEnterKey: false,
      showLoaderOnConfirm: true,
      scrollbarPadding: false,
      allowOutsideClick:false
       }).then((result) => {
  return this.auth.AvatarE.Numero = result.value
    })
  }

}

  }) 
}
async editarFin() { 
  Swal.fire({
    footer: '<strong>tip: Los datos que edites solo se veran reflejados en este pedido</strong>',
    title: 'Selecciona una Opcion',
    input: 'select',
    inputOptions: {
      1: 'NOMBRE',
      2: 'NUMERO',
      3: 'DIRECCION',
      4: 'ZONA'
    },
    showCancelButton: true,
    confirmButtonText: 'EDITAR',
    showLoaderOnConfirm: true,
  }).then((result) => {

    if (result.value == 1) {
      Swal.fire({
        title:'EDITAR NOMBRE DE QUIEN RECIBE',
        inputValue: `${this.nombreFinal}`,
        input: 'text',
        position: 'center',
        allowEnterKey: false,
        showLoaderOnConfirm: true,
        scrollbarPadding: false,
        allowOutsideClick:false
         }).then((result) => {
     return  this.nombreFinal = result.value
      })
    }
    if (result.value == 3) {
      Swal.fire({
        title:'EDITAR DIRECCION DE QUIEN RECIBE',
        inputValue: `${this.dirFinal}` ,
        input: 'text',
        position: 'center',
        allowEnterKey: false,
        showLoaderOnConfirm: true,
        scrollbarPadding: false,
        allowOutsideClick:false
         }).then((result) => {
     return  this.dirFinal = result.value
      })
    }
    if (result.value == 2) {
      Swal.fire({
        title:'EDITAR NUMERO DE QUIEN RECIBE',
        inputValue: `${this.numFinal}` ,
        input: 'number',
        position: 'center',
        allowEnterKey: false,
        showLoaderOnConfirm: true,
        scrollbarPadding: false,
        allowOutsideClick:false,
        inputValidator: (value) => {
          return new Promise((resolve : any) => {
            const num = parseInt(value);
            const isValidNumber = Number.isInteger(num) && value.toString().length === 8;
            
            if (isValidNumber) {
            resolve()   
            } else {
              resolve('El número debe ser entero y tener 8 dígitos');
            }
          });
        },
         }).then((result) => {
    return this.numFinal = result.value
      })
    }
    if(result.value == 4){
      Swal.fire({
        title:'EDITAR ZONA DE DONDE SE ENTREGA',
        inputValue: `${this.zonafinal}` ,
        input: 'select',
        position: 'center',
        allowEnterKey: false,
        showLoaderOnConfirm: true,
        allowOutsideClick:false,
        inputOptions: {
          'zona01': 'ZONA 01',
          'zona02': 'ZONA 02',
          'zona03': 'ZONA 03',
          'zona04': 'ZONA 04',
          'zona05': 'ZONA 05',
          'zona06': 'ZONA 06',
          'zona07': 'ZONA 07',
          'zona08': 'ZONA 08',
          'zona09': 'ZONA 09',
          'zona10': 'ZONA 10',
          'zona11': 'ZONA 11',
          'zona12': 'ZONA 12',
          'zona13': 'ZONA 13',
          'zona14': 'ZONA 14',
          'zona15': 'ZONA 15',
          'zona16': 'ZONA 16',
          'zona17': 'ZONA 17',
          'zona18': 'ZONA 18',
          'zona19': 'ZONA 19',
          'zona21': 'ZONA 21',
          'mixco1': 'MIXCO (zonas: 1, 2, 3, 4)',
          'mixco2': 'MIXCO (zonas: 5, 6, 7, 8, 9, 10, 11)',
          'villanueva': 'VILLA NUEVA',
          'sanmiguelpetapa': 'SAN MIGUEL PETAPA',
          'villacanales': 'VILLA CANALES',
          'amatitlan': 'AMATITLAN',
          'fraijanes': 'FRAIJANES',
          'sanjosepinula': 'SAN JOSE PINULA',
          'santacatarinapinula': 'SANTA CATARINA PINULA',
          'chinautla': 'CHINAUTLA'
        },
        inputValidator: async (value) => {
          if (!value) {
            return 'Este campo es obligatorio';
          }
          this.zonafinal = value
          await this.verificarMontos(this.zonainicio,value)
          if( this.pquienenvia){
            this.total = this.cod
           }
          else if( this.pquienrecibe){
            this.total = Number(this.cod) + Number(this.Precio1)
           }
        }
         })
    }
  }).then(()=>this.verificarMontos(this.zonainicio,this.zonafinal)
  )
}
async verificarMontos(zona1: string, zona2: string) {
  try {
    this.Precio1 = await this.ProductService.calcularTarifaAsync(zona1, zona2,this.esVIP);
  } catch (error) {
    console.error('Error al calcular la tarifa:', error);
    // Maneja el error de acuerdo a tus necesidades.
  }
}
random(min:number,max:number) {
 return Math.floor((Math.random() * (max - min + 1)) + min);
}
funcionPago() {
  this.verificarListo()
  this.mostrarVista(this.vistaFin);
  this.isCardVisible = false;
  this.VistaRecoger = false
  this.VistaEnvio = true

  this.verificarMontos(this.zonainicio,this.zonafinal).then(()=>{
    Swal.fire({
      footer: '<strong>tip: El pago de momento solo en efectivo</strong>',
      inputValue: `${this.producto}`,
      title: 'ELIGE UNA OPCION',
      input: 'radio',
      inputOptions:{
        1: 'ENVIO',
        2: 'MONTO DE COD'
      },
      showCancelButton: true,
      confirmButtonText: 'CONFIRMAR',
      showLoaderOnConfirm: true,
    }).then((result) => {
      if(result.value==1){
        Swal.fire({
          footer: '<strong>tip: El pago de momento solo en efectivo</strong>',
          inputValue: `${this.producto}`,
          title: 'QUIEN PAGARA EL ENVIO',
          input: 'radio',
          inputOptions:{
            1: 'QUIEN ENVIA',
            2: 'QUIEN RECIBE'
          },
          showCancelButton: true,
          confirmButtonText: 'CONFIRMAR',
          showLoaderOnConfirm: true,
        }).then((result)=>{
          if(result.value==1){
            this.pquienenvia = true
            this.pquienrecibe = false
            this.total = Number(this.cod)
           }
           else if(result.value==2){
            this.pquienenvia = false
            this.pquienrecibe = true
            this.total = Number(this.cod) + Number(this.Precio1)
           }
          if(result){
           return this.pago = result.value
          }
          else if(!result){
            return 'ESCRIBE ALGO'
          }
        })
      }
      if (result.value == 2) {
        Swal.fire({
          footer: '<strong>tip: El pago de momento solo en efectivo</strong>',
          inputValue: `${this.cod}`,
          title: 'INGRESA EL MONTO DE COD',
          input: 'number',
          showCancelButton: true,
          confirmButtonText: 'CONFIRMAR',
          showLoaderOnConfirm: true,
          inputAttributes: {
            step: '0.01', // Esto permite números decimales con dos decimales
            min: '0', // Esto evita números negativos
          },
        }).then((result) => {
          if (result.value !== null) {
            if (this.pquienenvia || (!this.pquienenvia && !this.pquienrecibe)) {
              this.cod = result.value;
              this.total = Number(this.cod);
            }
            if (this.pquienrecibe) {
              this.cod = result.value;
              this.total = Number(this.cod) + Number(this.Precio1);
            }
          }
        });
      }
  
  
  
  
  }) 
  })
}
funcionPaquete(){
  Swal.fire({
    footer: '<strong>tip: Empaca bien tus paquetes</strong>',
    inputValue: `${this.producto}`,
    title: 'DESCRIBE LO QUE ENVIAS',
    input: 'text',
    showCancelButton: true,
    confirmButtonText: 'CONFIRMAR',
    showLoaderOnConfirm: true,
  }).then((result) => {
    if(result){
      this.producto = result.value
    }
   else if(!result){
      return 'ESCRIBE ALGO'
    }
  }) 
}
updatingFavorite = false; // Variable para rastrear si se está procesando una actualización
toggleFavorito(item: any) {
  if (this.updatingFavorite) {
    // Evitar múltiples clics mientras se está procesando una actualización
    return;
  }

  this.updatingFavorite = true; // Establecer como verdadero mientras se procesa la actualización
  const usuarioId = this.auth.IdUsuario;
  const ubicacionesRef = this.afs.collection('Usuarios').doc(usuarioId).collection('UBICACIONES');

  ubicacionesRef.snapshotChanges().pipe(take(1)).subscribe((snapshots) => {
    const batch = this.afs.firestore.batch();

    // Itera a través de las ubicaciones y actualiza el campo "PRINCIPAL" en consecuencia
    snapshots.forEach((snapshot) => {
      const ubicacionDoc = snapshot.payload.doc;
      const ubicacionData = ubicacionDoc.data();

      if (ubicacionData.Referencia === item.Referencia) {
        // Establece el campo "PRINCIPAL" en true para la ubicación seleccionada
        batch.update(ubicacionDoc.ref, { PRINCIPAL: true });
      } else {
        // Establece el campo "PRINCIPAL" en false para las demás ubicaciones
        batch.update(ubicacionDoc.ref, { PRINCIPAL: false });
      }
    });

    // Ejecuta la transacción en Firestore
    batch.commit()
      .then(() => {
        // Realiza cualquier acción adicional necesaria después de actualizar el estado de favorito en Firestore
        this.updatingFavorite = false; // Restablecer como falso después de completar la actualización
      })
      .catch(error => {
        // Maneja errores al actualizar el estado de favorito en Firestore
        this.updatingFavorite = false; // Restablecer como falso en caso de error
      });
  });
}

async  enviarMensajeTelegram(mensaje,id) {
  const url = `https://api.telegram.org/bot${this.telegramBotToken}/sendMessage`;
  const body = {
    chat_id: id,
    text: mensaje,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (error) {
    Swal.fire({
      position: 'top-end',
      icon: 'warning',
      title: 'NO SE NOTIFICO LA TRANSACCION',
      showConfirmButton: false,
      timer: 1000
    });
    console.error('Error al enviar mensaje a Telegram:', error);
  }
}


}