import { Injectable,  EventEmitter, Output  } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr'; // importa ToastrModule
import { Socket } from 'ngx-socket-io';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/services/auth.service';


@Injectable({
  providedIn: 'root'
})

export class WebsocketService extends Socket  {
  suscritoAGuias: string[] = [];
  private mensajesSubject = new Subject<any>();
  @Output() outEven: EventEmitter<any> = new EventEmitter(); 
  public MSJ = [];
  chat=''
  suscrito: boolean  ; 
  lat: any;
  lng: any;
  public miObservable
  isTimeoutActivated = false;
  mensajes;
  guia: any;
  public messages$ = new Subject();

  ngOnInit(): void {
   }

  constructor(private router: Router,private toastr: ToastrService,public at: AuthService) { 
    super({url: environment.serverSocket}); 
      interface Mensaje {
        guia: number;
        de: string;
        msj: string;
      }
    navigator.geolocation.watchPosition(position =>{
    this.lat =  position.coords.latitude,
    this.lng = position.coords.longitude
          })
}

  suscritoAGuia(sala: string): boolean {
    return this.suscritoAGuias.includes(sala)
  }

private mostrarNotificacion(mensaje: string) {
  if ('Notification' in window) {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        const notification = new Notification('Mensaje del conductor', {
          body: mensaje
        });
      }
    });
  }

  

  
  
  
  this.toastr.success(mensaje, 'Nuevo mensaje recibido');
}


abrirChat(guia: string, mensaje: string,conductor): void {
  this.ioSocket.emit('msjCliente', { 
    de:'Cliente', 
    a: guia, 
    msj: mensaje,
    sala: `${guia}${conductor}${this.at.IdUsuario}`,
    tiempo: new Date()
   });
}

suscribirse(sala: string,guia : string): void {
  let room = {
    sala: sala,
    guia:  guia
  }
  this.ioSocket.emit('join', room);
  this.suscritoAGuias.push(sala);
}
desuscribirse(sala: string): void {
  this.ioSocket.emit('unsubscribe', sala);
  const index = this.suscritoAGuias.indexOf(sala);
  if (index !== -1) {
    this.suscritoAGuias.splice(index, 1);
  }
}

notificacion(){
  this.ioSocket.on('notificacion',(data)=>{
    this.MSJ.push(data.a)
    this.mostrarNotificacion(data.msj)
  })
}
getMensajes(): Observable<any> {
  return new Observable<any>(observer => {
    this.ioSocket.on('msj', (data) => {
      observer.next(data);
      this.mostrarNotificacion(data.msj)
    });
    return () => {
      this.ioSocket.disconnect();
    };
  });
}



}