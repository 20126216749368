<div class="row mt-1">
    <div class=" col-lg-7 col-sm-8 col-md-8 mx-auto mt-5">

<div class="card" *ngIf="user$ | async as user; else loading">

        <div class="card-body mb-0">
        <h2 class="card-title text-center">GRACIAS POR TU REGISTRO</h2>
        <div class="form-group mt-2">
        <p class="text-center">
             Hemos enviado un correo de verificacion a <strong>{{user?.email}}</strong>
        </p>
        <p class="text-center mt-5text-muted font-size-sm">
             Si aun no recibis el correo de verificacion, intenta reenviarlo
        </p>
        </div>   
        <div class="form-group text-center mt-1">
        <button class="btn-block btn-lg btn-secondary" (click)="onSendEmail()">Renviar Correo de Verificacion</button>
        </div>
        
        <div class="form-group mt-1">
            <p><a role="button" (click)="ngOnDestroy()"> Inicia Sesion </a> </p>
            </div> 
    </div>
    </div>

<ng-template #loading>
    ENVIANDO CORREO...

</ng-template>

</div>
</div>