import { Component, OnInit} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/auth/services/auth.service';
import Swal from 'sweetalert2';
import { ProductService } from '../../services/product.service';
import { environment } from 'src/environments/environment';
interface Departamento {
  nombre: string;
  municipios: string[];
}


@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss']
})
export class ProductosComponent implements OnInit {
  telegramBotToken = environment.telegramToken;
  formulario: FormGroup;
  comision: number= 0
  costoEnvio: number = 35;
  montoTotalACobrar: number = 0;
  montoTotalARecibir: number = 0;
  departamentos: Departamento[] = [
    { nombre: 'Guatemala', municipios: ['Guatemala City', 'Mixco', 'Villa Nueva'] },
    { nombre: 'Quetzaltenango', municipios: ['Quetzaltenango', 'Salcajá', 'Almolonga'] },
    // Agrega más departamentos y municipios aquí
  ];
  municipios: string[] = [];
  guia: string;
  formData: any;
  esVIP: boolean = false;
  loading: boolean= false;
  public perfil= {
    CODIGO : "",
    NOMBRE : "",
    CORREO : "",
    FOTO : "",
    numero:"",
    ref:"",
    Direccion:"",
  }
  grupo= environment.grupoFZ
  constructor(public ProductService: ProductService,private fb: FormBuilder,public fs: AngularFirestore,public at: AuthService) {
    this.at.user$.subscribe((data) => {
      this.perfil.CODIGO = data.uid
      this.perfil['ref'] = data.ref
      this.perfil['NOMBRE'] = data.displayName
      this.perfil['CORREO'] = data.email
      this.perfil['numero'] = data.numero
      this.perfil['FOTO'] = data.photoURL
      this.perfil['Direccion'] = data.Direccion
      console.log(data)
    });
    this.formulario = this.fb.group({
      nombre: ['', Validators.required],
      numero: ['', [Validators.required, Validators.pattern('^[0-9]{8}$')]],
      cobrar: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(0.01)]], 
      direccion:['', Validators.required],
      soloEnvio:[false],
    });
    this.formulario.valueChanges.subscribe(values => {
      const { cobrar, soloEnvio } = values;
      this.actualizarMontos(cobrar, soloEnvio);
    });
    // Escuchar cambios en el campo 'departamento' para cargar municipios
    this.formulario.get('departamento')?.valueChanges.subscribe(departamento => {
      this.cargarMunicipios(departamento);
    });
  }
  actualizarMonto(): void {

    const soloEnvioControl = this.formulario.get('soloEnvio');
    if (soloEnvioControl && soloEnvioControl.value) {
      // El checkbox soloEnvio está marcado
      this.formulario.get('cobrar').setValue(0);
      this.montoTotalACobrar = 0
      // Realiza las acciones necesarias aquí
    } else {
      soloEnvioControl.reset()

      // El checkbox soloEnvio no está marcado
      // Realiza las acciones necesarias aquí
    }
  } 
  ngOnInit(): void {
    


  }
  onWheel(event: WheelEvent): void {
    if (document.activeElement === event.target) {
      event.preventDefault();
    }
  }
  actualizarMontos(montoCobrar: string, soloEnvio: boolean): void {
    if (soloEnvio) {
      this.formulario.get('cobrar').disable({emitEvent: false});
      this.montoTotalACobrar = 0;
      this.comision = 0;
      this.montoTotalARecibir =Number(0 - this.costoEnvio ) ;
    }else {
      this.formulario.get('cobrar').enable({emitEvent: false});
      const monto = parseFloat(montoCobrar) || 0;
      this.comision = parseFloat((monto * 0.035).toFixed(2));
      this.montoTotalACobrar = monto;
      const montoMenosEnvio = monto - this.costoEnvio;
      const montoMenosPorcentaje = montoMenosEnvio - (monto * 0.035);
      this.montoTotalARecibir = montoMenosPorcentaje;
    }
  }
  cargarMunicipios(departamentoNombre: string): void {
    const departamento = this.departamentos.find(d => d.nombre === departamentoNombre);
    this.municipios = departamento ? departamento.municipios : [];
    this.formulario.get('municipio')?.reset(); // Resetea el campo municipio al cambiar departamento
  }
  generarNumeroGuia(): Promise<string> {
    const guiasRef = this.fs.collection('CONTADORFZ').doc('contador');
  
    return this.fs.firestore.runTransaction(async (transaction) => {
      const doc = await transaction.get(guiasRef.ref);
  
      if (!doc.exists) {
        // Si no hay datos existentes, inicializa el contador en 1
        transaction.set(guiasRef.ref, { contador: 1 });
        return 'FZ0001';
      }
  
      // Incrementa el contador existente
      const contador = doc.data().contador + 1;
      transaction.update(guiasRef.ref, { contador });
  
      // Genera el número de guía con el formato "VR0000"
      const numeroGuia = `FZ${contador.toString().padStart(4, '0')}`;
  
      // Devuelve el número de guía generado
      return numeroGuia;
    })
    .then((numeroGuia) => {
      return numeroGuia;
    })
    .catch((error) => {
      console.error('Error al generar el número de guía:', error);
  
      // Genera un número aleatorio de 4 dígitos
      const numeroAleatorio = Math.floor(1000 + Math.random() * 9000);
  
      // Genera un texto aleatorio de 4 caracteres
      const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      let textoAleatorio = '';
      for (let i = 0; i < 4; i++) {
        textoAleatorio += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
      }
  
      // Genera el número de guía alternativo combinando el texto y número aleatorio con el formato "VR0000"
      const numeroGuiaAlternativo = `FZ${textoAleatorio}${numeroAleatorio.toString().padStart(4, '0')}`;
      return numeroGuiaAlternativo;
    });
  }
  obtenerCamposFaltantes(): string[] {
    const camposFaltantes: string[] = [];
    Object.keys(this.formulario.controls).forEach(controlName => {
      const control = this.formulario.get(controlName);
      if ((control.invalid && (control.dirty || control.touched)) || (control.errors?.required && control.value === '')) {
        camposFaltantes.push(controlName);
      }
    });
    return camposFaltantes;
  }
  camposInvalidosVacios(): boolean {
    return Object.keys(this.formulario.controls).some(controlName => {
      const control = this.formulario.get(controlName);
      return control.invalid && !control.value && (control.dirty || control.touched);
    });
  }
  marcarCamposInvalidosVacios(): void {
    Object.keys(this.formulario.controls).forEach(controlName => {
      const control = this.formulario.get(controlName);
      if (control.invalid && !control.value && (control.dirty || control.touched)) {
        control.markAsTouched();
      }
    });
  }
  async mostrarMensajeExito(): Promise<void> {
    await Swal.fire({
      title: 'Guía creada con éxito',
      icon: 'success',
      timer: 3000, // Se cerrará automáticamente después de 3 segundos
      showConfirmButton: false
    });
  } 
  envioEnProceso: boolean = false;
  async onSubmit(): Promise<void> {
    this.costoEnvio = 35;
    // Evitar múltiples envíos mientras se está procesando uno
    if (this.envioEnProceso) {
      return;
    }
    this.envioEnProceso = true;
    
    // Verificar si hay una conexión de internet antes de continuar
    if (!navigator.onLine) {
      Swal.fire({
        title: 'Sin conexión',
        text: 'Por favor, conéctate a internet e intenta nuevamente.',
        icon: 'error',
        timer: 5000,
        showConfirmButton: false
      });
      this.envioEnProceso = false;
      return;
    }
  
    // Verificar si at.IdUsuario existe y no es nulo
    if (!this.at || !this.at.IdUsuario) {
      console.error('No se pudo obtener el ID del usuario.');
      this.envioEnProceso = false;
      return;
    }
    
    let ref = this.perfil.ref;
    if (!ref) {
        ref = 'sin referido';
    }


    const shortenedId = this.at.IdUsuario.substring(0, 5); // Tomar los primeros 5 caracteres del ID
    const storedVIPStatus = localStorage.getItem(shortenedId);
    // Verificar si se encontró un estado del VIP en el almacenamiento local
    if (storedVIPStatus !== null) {
      this.esVIP = storedVIPStatus === 'true'; // Convertir de cadena a booleano
    } else {
      this.esVIP = false;
    }
    
    if (this.esVIP) {
      if (this.formulario.valid && this.camposRequeridosValidos()) {
        try {

          this.guia = await this.generarNumeroGuia();
          this.formData = { ...this.formulario.value };
          this.formData.recibir = parseFloat(this.montoTotalARecibir.toFixed(2));
          this.formData.comision = parseFloat(this.comision.toFixed(2)); 
          this.formData.envio = this.costoEnvio;
          this.formData.USUARIO = this.at.IdUsuario;
          this.formData.fecha = new Date();
          this.formData.ref = ref
          this.formData.CorreoUsuario = this.at.CorreoUsuario;
          this.formData.Usuario = this.at.NombreUsuario;
          this.formData.NumeroEnvia = this.at.numero;
          this.formData.DireccionEnvia = this.at.Direccion;
          this.formData.guia = this.guia;
          this.formData.Estado = 'SOLICITADO';
          this.formData.guia = this.guia;
          const mensajeGrupo = `
          GUIA ${this.guia}  GENERADA
          DE: ${this.at.NombreUsuario} 
          FECHA: ${new Date()} 
         `
          await this.fs.collection('departamental').doc(this.guia).set(this.formData);
          await this.enviarMensajeTelegram(mensajeGrupo,this.grupo),
          this.mostrarMensajeExito();
        } catch (error) {
          console.error('Error al enviar la guía:', error);
          Swal.fire({
            title: 'Error al enviar la guía',
            text: 'Por favor, intenta nuevamente más tarde.',
            icon: 'error',
            timer: 5000,
            showConfirmButton: false
          });
          // No establecer envioEnProceso en false aquí para evitar bloqueos
          return; // Evitar que se ejecuten las líneas de reinicio si hay un error
        }
        // Reinicio de los campos
        this.formulario.reset();
        this.montoTotalARecibir = 0; 
        this.comision = 0; 
        this.costoEnvio = 35; 
        this.guia = ''; 
        this.formData = {};
      } else {
        // Mostrar mensaje de error indicando campos incompletos
        const camposFaltantes = this.obtenerCamposFaltantes();
        const mensajeError = `Verificar datos faltantes`;
        Swal.fire({
          title: mensajeError,
          text: ` ${camposFaltantes.join(', ')}`,
          icon: 'error',
          timer: 5000,
          showConfirmButton: false
        });
      }
    } else {
      Swal.fire({
        title: 'No eres VIP',
        icon: 'error',
        timer: 3000, // Se cerrará automáticamente después de 3 segundos
        showConfirmButton: false
      });
    }
    
    this.envioEnProceso = false;
    console.log(this.envioEnProceso);
  }
  
  private camposRequeridosValidos(): boolean {
    // Verificar si los campos requeridos tienen valores válidos
    return this.at.NombreUsuario?.trim().length > 0 &&
           this.at.numero != null && // Verificar si es diferente de null o undefined
           this.at.Direccion?.trim().length > 0 &&
           this.at.CorreoUsuario?.trim().length > 0;
  }
  
  async  enviarMensajeTelegram(mensaje,id) {
    const url = `https://api.telegram.org/bot${this.telegramBotToken}/sendMessage`;
    const body = {
      chat_id: id,
      text: mensaje,
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'NO SE NOTIFICO LA TRANSACCION',
        showConfirmButton: false,
        timer: 1000
      });
      console.error('Error al enviar mensaje a Telegram:', error);
    }
  }
}