import {AngularFirestore, AngularFirestoreDocument}  from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import {AngularFireAuth}from '@angular/fire/auth';
import {switchMap} from 'rxjs/operators';
import {auth} from 'firebase/app'
import { Observable, of } from 'rxjs';
import { User } from '@app/shared/models/user.interface';
import { RoleValidator } from '@auth/helpers/roleValidator';
import Swal from 'sweetalert2';

@Injectable({providedIn: 'root'})
export class AuthService extends RoleValidator 
  {
  public user$ : Observable<User>;
  IdUsuario: string;
  CorreoUsuario: string;
  ref : string
  NombreUsuario: string;
  Direccion: string;
  FotoUsuario: string;
  numero: string;
  RolUsuario: string;
  verificado : boolean
Avatar: any =  JSON.parse(localStorage.getItem('PERSONAL'))
ap = localStorage.getItem('ap')
AvatarE: any = JSON.parse(localStorage.getItem('EMPRESA')) 
ae = localStorage.getItem('ae')

ngOnInit(): void {
        this.user$.subscribe((data)=>{
          this.ref = data.ref
          this.IdUsuario= data.uid
          this.CorreoUsuario = data.email
          this.NombreUsuario = data.displayName
          this.Direccion = data.Direccion
          this.numero = data.numero
          this.FotoUsuario = data.photoURL
          this.RolUsuario = data.role
          this.verificado = data.emailVerified

        }
        )



        
}
    constructor(public afAuth: AngularFireAuth, public afs: AngularFirestore) 
      { super();
                this.user$ = this.afAuth.authState.pipe(
                switchMap ( (user) => {
                if (user)
                {
                return this.afs.doc<User>(`Usuarios/${user.uid}`).valueChanges(); //user
                }
                return of(null);
                })),
                this.user$.subscribe((data) => {
                  if (data) {
                    this.IdUsuario = data.uid || '';
                    this.CorreoUsuario = data.email || '';
                    this.NombreUsuario = data.displayName || '';
                    this.Direccion = data.Direccion || '';
                    this.numero = data.numero || '';
                    this.FotoUsuario = data.photoURL || '';
                    this.RolUsuario = data.role || '';
                    this.verificado = data.emailVerified || false;
                  } else {

                  }
                });
                
      }
      fAvatar(){
        if(!localStorage.getItem('PERSONAL')){
          this.fap()
          this.Avatar = {
            Nombre:this.NombreUsuario,
            Numero:this.numero
          }
        }
    else {
      console.log("2p")
      this.fap()
      this.Avatar = JSON.parse(localStorage.getItem('PERSONAL'))
    }
      }
    fAvatarE(){ 
        if(!localStorage.getItem('EMPRESA')){
          this.fae()
          this.AvatarE = {
            Nombre:"",
            Numero:""
          }
        }
    else {
      this.fae()
      console.log("2E")
      this.AvatarE =  JSON.parse(localStorage.getItem('EMPRESA')) 
    }
      }
    fap(){
      if(!localStorage.getItem('ap')){
        this.ap = String(1)
      }
    else {
    console.log("2ap")
    this.ap = localStorage.getItem('ap')
    }
    
    } 
                 
    fae(){
      if(!localStorage.getItem('ae')){
        this.ae = String(1)
      }
    else {
    console.log("2ae")
    this.ae = localStorage.getItem('ae')
    }
    
    } 

    
    //  logearse con google
    async loginGoogle(): Promise<User> { 
      try {
        const { user } = await this.afAuth.signInWithPopup(new auth.GoogleAuthProvider()); 
        await this.updateUserData(user, user.displayName, user.phoneNumber);
        return user;
      } catch (error) {
        console.log(error);
      }
    }
    //resetear contraseña
    async resetPassword (email:string):Promise<void>
    {
          try {
          return this.afAuth.sendPasswordResetEmail(email);
          }
          catch(error){console.log(error)}
    }
    // enviar correo de verificacion
    async sendverificationEmail(): Promise <void>
    {
      return (await this.afAuth.currentUser).sendEmailVerification();
     
    }
    // funcion de login correo normal
    async login(email: string, password: string): Promise <User> 
    {try { 
    const {user} = await this.afAuth.signInWithEmailAndPassword(email,password);
     return user
    } catch(error) 
    { 
      if(error.code == 'auth/internal-error'){
      this.Sinautorizacion('ERROR',false,'error','VERIFICA TUS DATOS','top')
      }
      console.log(error);}
    }
    // metodo de registro correo normal
    async register(email: string, password: string): Promise <User>
    {
      try {
      const {user} = await this.afAuth.createUserWithEmailAndPassword(email,password);
      
      if(!user.emailVerified) 
      this.Sinautorizacion('ATENCION',false,"warning","Revisa tu bandeja de entrada","top")
      this.sendverificationEmail();
      return user;
      }
      catch(error)
      { if(error.code == 'auth/email-already-in-use')
      {
        this.Sinautorizacion('ERROR',false,'error','El Correo esta siendo usado en otra cuenta','top')
      }
      if(error.code == 'auth/invalid-email')
      {
        this.Sinautorizacion('ERROR',false,'error','Utilice un Formato de Correo correcto','top')
      }
      if(error.code == 'auth/weak-password')
      {
        this.Sinautorizacion('ERROR',false,'error','La contraseña debe contener almenos 6 caracteres','top')
      }
      
       console.log(error.code);}
    }
    // metodo para cerrar sesion 
    async logout()    {
      try {
      await this.afAuth.signOut();
      } catch (error) 
      { console.log(error);}
    } 
    seleccionar_avatar(tipo: string){
      var a
      var todos =  {}
      var todos1 =  {}
      var todos2 =  {}
      for(a=1;a<=4;a++){todos[a] = `<img src="../assets/${a}.png" class="rounded img-thumbnail">`}
       for(a=5;a<=8;a++){todos1[a] = `<img src="../assets/${a}.png" class="rounded img-thumbnail">`} 
       for(a=9;a<=12;a++){todos2[a] = `<img src="../assets/${a}.png" class="rounded img-thumbnail">`}
      if(tipo == "PERSONAL")
       {           
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          title: 'Selecciona tu avatar Personal',
          input: 'radio',
          grow: 'fullscreen',
          showCancelButton:false,
          footer: '<strong>CONFIRMA O BUSCA MAS AVATAR</strong>',
          confirmButtonText:'Confirmar o Siguiente',
          inputOptions:todos,
          inputValidator: (value) => {
            this.ap = value
            if (!value) {
              Swal.fire({
              allowOutsideClick: false,
              allowEscapeKey: false,
              showCancelButton:false,
              footer: '<strong>CONFIRMA O BUSCA MAS AVATAR</strong>',
              confirmButtonText:'Confirmar o Siguiente',
              title: 'Selecciona tu avatar Personal',
              input: 'radio',
              grow: 'fullscreen',
              inputOptions:todos1,
              inputValidator: (value) => { 
                this.ap = value
                localStorage.setItem("ap",value)
                if(!value){
                  Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showCancelButton:false,
                    footer: '<strong>SELECCIONA ALMENOS 1</strong>',
                    confirmButtonText:'Confirmar',
                    title: 'Selecciona tu avatar Personal',
                    input: 'radio',
                    grow: 'fullscreen',
                    inputOptions:todos2,
                    inputValidator: (valor) => { this.ap = valor
                      localStorage.setItem("ap",valor)
                      if(!valor){
                        return 'Selecciona una opcion'
                    }}})
                }  
                return ''
              }
              })
              return ''
            }
          }




        }
        )
      }
      if(tipo == "EMPRESA")
       { 
            
        Swal.fire({
          title: 'Selecciona tu avatar de Empresa',
          input: 'radio',
          grow: 'fullscreen',
          footer: '<strong>CONFIRMA O BUSCA MAS AVATAR</strong>',
          confirmButtonText:'Confirmar o Siguiente',
          inputOptions:todos,
          inputValidator: (value) => {
            this.ae = value
           localStorage.setItem("ae",value)
            if (!value) {
              Swal.fire({
              footer: '<strong>CONFIRMA O BUSCA MAS AVATAR</strong>',
              confirmButtonText:'Confirmar o Siguiente',
              title: 'Selecciona tu avatar de Empresa',
              input: 'radio',
              grow: 'fullscreen',
              inputOptions:todos1,
              inputValidator: (value) => { 
                this.ae = value
                localStorage.setItem("ae",value)
                if(!value){
                  Swal.fire({
                    footer: '<strong>SELECCIONA ALMENOS 1</strong>',
                    confirmButtonText:'Confirmar',
                    title: 'Selecciona tu avatar de Empresa',
                    input: 'radio',
                    grow: 'fullscreen',
                    inputOptions:todos2,
                    inputValidator: (valor) => { this.ae = valor
                      localStorage.setItem("ae",valor)
                      if(!valor){
                        return 'Selecciona una opcion'
                    }}})
                }  
                return ''
              }
              })
              return ''
            }
          }




        }
        )
      }       
    }
    async editarDatosAvatar(tipo: string) {
      const opciones = {
        1: 'NOMBRE',
        2: 'NUMERO',
        3: 'DIRECCION',
      };
    
      const opcionSeleccionada = await Swal.mixin({
        html: '<strong>QUE DESEAS MODIFICAR?</strong>',
        input: 'select',
        inputOptions: opciones,
        confirmButtonText: 'EDITAR',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        focusConfirm: false,
      }).queue([{
        inputValue: opciones[1],
      }]);
    
      if (opcionSeleccionada.value) {
        const opcion = opciones[opcionSeleccionada.value[0]];
        let valorInicial = '';
    
        switch (opcion) {
          case 'NOMBRE':
            valorInicial = this.NombreUsuario;
            break;
          case 'NUMERO':
            valorInicial = this.numero;
            break;
          case 'DIRECCION':
            valorInicial = this.Direccion;
            break;
          default:
            break;
        }
    
        const inputPlaceholder = opcion === 'NOMBRE' ? 'Ej. Juan José Arevalo' : (opcion === 'NUMERO' ? 'Ej. 34445455' : 'Ej. Dirección');
    
        const valorNuevo = await Swal.mixin({
          title: `EDITAR ${opcion}`,
          inputPlaceholder,
          inputValue: valorInicial,
          input: 'text',
          position: 'center',
          allowEnterKey: false,
          showCancelButton: true,
          confirmButtonText: 'Guardar',
          cancelButtonText: 'Cancelar',
          inputValidator: (value) => {
            if (opcion === 'NUMERO' && !/^\d{8}$/.test(value)) {
              return 'El número debe tener 8 dígitos';
            }
            if (!value) {
              return 'Este campo es obligatorio';
            }
          },
        }).queue([{
          inputValue: valorInicial,
        }]);
    
        if (valorNuevo.value && !valorNuevo.dismiss) {
          await this.actualizarCampo(opcion, valorNuevo.value[0]);
        }
      }
    }
    async actualizarCampo(opcion: string, valor: string) {
      if (opcion === 'NOMBRE') {
        await this.afs.collection('Usuarios').doc(this.IdUsuario).update({ displayName: valor });
        this.NombreUsuario = valor;
        localStorage.setItem('PERSONAL', JSON.stringify(this.Avatar));
      } else if (opcion === 'NUMERO') {
        await this.afs.collection('Usuarios').doc(this.IdUsuario).update({ numero: valor });
        this.numero = valor;
        localStorage.setItem('PERSONAL', JSON.stringify(this.Avatar));
      }
      else if (opcion === 'DIRECCION') {
        await this.afs.collection('Usuarios').doc(this.IdUsuario).update({ Direccion: valor });
        this.Direccion = valor;
        localStorage.setItem('DIRECCION', JSON.stringify(this.Avatar));
      }

    }
    public updateUserData(user: User, nombre: string, numero?: string, referralId?: string): Promise<void> {
      const userRef: AngularFirestoreDocument<User> = this.afs.collection('Usuarios').doc(user.uid);
    
      return new Promise((resolve, reject) => {
        userRef.get().toPromise().then((userData) => {
          const isFirstTimeRegistration = !userData.exists;
          if (isFirstTimeRegistration) {
            const data: User = {
              uid: user.uid,
              email: user.email,
              emailVerified: user.emailVerified,
              displayName: nombre,
              numero: numero || '', // Proporciona un valor predeterminado o excluye si es undefined
              photoURL: user.photoURL || '',
              role: 'USUARIO',
              ref: referralId || '', // Proporciona un valor predeterminado o excluye si es undefined
              firstTimeRegistration: true,
            };
            console.log(data);
            return userRef.set(data, { merge: true }).then(() => resolve());
          } else {
            console.log('Ya se registró antes, no se actualizan los datos.');
            resolve();
          }
        }).catch(error => reject(error));
      });
    }
    public auto(user: User) {
      const userRef: AngularFirestoreDocument<User> = this.afs.doc(`Usuarios/${user.uid}`);
      const data: Partial<User> = {
        emailVerified: user.emailVerified || false,
      };    
        return userRef.update(data).catch(error => {
          console.error("Error al actualizar el usuario:", error);
        });
    }
    Sinautorizacion (titulo,sesion,icono,mensaje,posicion){
      if(sesion){
       this.logout()}
           Swal.fire({
           title:mensaje,
           position: posicion,
           icon: icono,
           text: 'Error al iniciar Sesion verifica tus datos',
           showConfirmButton: false,
           timerProgressBar : true,
           timer: 4000,
         }) 
   
       
       }
  }
