import { AuthService } from '@app/auth/services/auth.service';
import { Component, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'firebase';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  })

  @Injectable({
    providedIn: 'root'
  })
  
export class NavbarComponent  
{
  public showNavbar = true;
  public user$: Observable<User>=this.authSvc.afAuth.user;


  constructor(public authSvc: AuthService, private router: Router) {}

    async onLogout()
    {
    try{
          await this.authSvc.logout();
          this.router.navigate(['/login']);
        } catch (error)
        {console.log(error)} 
    }
}
