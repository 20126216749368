import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,  Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { take, map, tap } from 'rxjs/operators';
import { User } from '@app/shared/models/user.interface';

@Injectable({
  providedIn: 'root'
})
export class CanLoginGuard implements CanActivate {
 private user: User;
 
 constructor(private authSvc: AuthService,private router: Router){}
 canActivate(
   next: ActivatedRouteSnapshot,
   state: RouterStateSnapshot):
   Observable <boolean> |  Promise<boolean> |  boolean {
   return this.authSvc.user$.pipe(
     take(1),
     map ((user) => !user ),
     tap(canEdit => {
       if(!canEdit){
        this.router.navigate(['/home']);
       }
     })
   )
 }
 
}
