import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { take, map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class CanEditGuard implements CanActivate {
  constructor(private authSvc: AuthService, private router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):
    Observable <boolean> |  Promise<boolean> |  boolean {
      return   this.authSvc.user$.pipe(
        take(1),
        map ((user)=> user && this.authSvc.isUsuario(user) ),
        tap(canEdit => {
          if(!canEdit){
           this.authSvc.logout()
           this.router.navigate(['/login']);
           const Toast = Swal.mixin({
             toast: true,
             position: 'top',
             showConfirmButton: false,
             timer: 3000,
             timerProgressBar: true,
             onOpen: (toast) => {
               toast.addEventListener('mouseenter', Swal.stopTimer)
               toast.addEventListener('mouseleave', Swal.resumeTimer)
             }
           })
           Toast.fire({
             icon: 'error',
             title: 'ACEESO DENEGADO',
             text: 'Exclusivo para Usuarios Verficados'
           })
   
           
          }
        }
        )
      )
  }
  
}
