export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyDgvprp6enB_ZqHD3ETXynLPCIC6PSrMb0",
    authDomain: "viver-3ff3f.firebaseapp.com",
    databaseURL: "https://viver-3ff3f.firebaseio.com",
    projectId: "viver-3ff3f",
    storageBucket: "viver-3ff3f.appspot.com",
    messagingSenderId: "1088058405753",
    appId: "1:1088058405753:web:5e2beb991b06a6493d35a8",
    measurementId: "G-3M2W0BH775",
  },
  serverSocket: 'http://localhost:5000',
  telegramToken: '6117925601:AAGIZTLImS7E1yIKtZGmcxovzm_X2BdyN-k',
  ad: '1533688100',
  grupo: '-1002062207496',
  grupoFZ: '-4288807648'
  
};

