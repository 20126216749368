<div class="container-center">
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="map-container m-0">
<!-- MAPA      -->
<google-map (mapClick)="agregarMarker($event)" *ngIf="!pedidoEnviado" height="100vh" width="{{100}}%" [center]="center" [zoom]="zoom" [options]="options">
  <map-marker *ngFor="let markerPosition1 of markerInicio" 
    (mapDragstart)="arrastrarMi()"
    (mapDragend)="arrastrarM2($event)"
    [position]="markerPosition1"
    [options]="mFinOp">
  </map-marker>
  <map-marker *ngFor="let markerPosition of markerFin" 
    (mapDragstart)="arrastrarMi()"
    (mapDragend)="arrastrarM($event)"
    [position]="markerPosition"
    [options]="mInicioOp">
  </map-marker>
  

</google-map>
<!--BUSCADOR DE DIRECCION-------------------------------------------> 
<div *ngIf="!pedidoEnviado" class="container">
    <div id="search-container">
      <div class="col-12">
        <ul class="list-group text-left" [hidden]="search.length === 0">
          <li class="list-group-item">
            <i class="fas fa-crosshairs" (click)="Destino()"></i> TU UBICACIÓN
          </li>
          <li class="list-group-item" *ngFor="let result of searchResults">
            {{ result.description }}
          </li>
        </ul>
      </div>
    </div>
    <div class="input-group search rounded-pill">
      <button class="btn rounded-pill"
        [ngClass]="{'btn-warning': VistaEnvio, 'btn-info': VistaRecoger}"
        style="width: 5%; height: 100%;"
        (click)="toggleVista()">
      </button>
      <input *ngIf="VistaEnvio" type="text" class="form-control-plaintext" placeholder="{{ placeholder }}"
        ngx-google-places-autocomplete [options]="options1" #placesRef="ngx-places"
        (onAddressChange)="handleAddressChange($event, true)" />
        <input *ngIf="!VistaEnvio" type="text" class="form-control-plaintext" placeholder="{{ placeholder }}"
        ngx-google-places-autocomplete [options]="options1" #placesRef="ngx-places"
        (onAddressChange)="handleAddressChange($event, false)" />

    </div>
</div>

<!--FIN BUSCADOR DE DIRECCION------------------------------------------->        
<!--esto es una prueba------------------------------------------->   
<div *ngIf="!pedidoEnviado" class="container p-0 align-items-end ">
  <div id="card-container" *ngIf="isCardVisible && VistaRecoger">
    <div class="widget widget-reminder col-lg-6 col-md-6">
      <h5 class="text-center">ENVIA</h5>  
      <h5><strong *ngIf="this.zonainicio" >Zona de Recoleccion: {{this.zonainicio}} </strong> <span *ngIf="!this.zonainicio" style="color: red">Zona de recoleccion **Obligatorio </span></h5>
      <div class="close-button" (click)="toggleCardVisibility('envia')">&#10006;</div>
      <div class="widget-reminder-container"> 
          <div class="widget-reminder-divider bg-primary"></div>
          <div class="widget-reminder-content">
            <p *ngIf="this.auth.NombreUsuario" class="m-0 p-0">- {{this.auth.NombreUsuario}}</p> 
            <p *ngIf="!this.auth.NombreUsuario"  class="m-0 p-0" style="color: red;" class="widget-desc">Nombre: **Obligatorio</p> 
            <div  class="widget-reminder-content">        
             <p *ngIf="!this.auth.numero" class="m-0 p-0" style="color: red;">Numero: **Obligatorio</p>
            <p *ngIf="this.auth.numero" class="m-0 p-0"> -{{this.auth.numero}}</p>
            </div>
            <div  class="widget-reminder-content">
                <!-- <i class="fa fa-map-pin"></i> -->
                <p *ngIf="this.auth.Direccion" class="m-0 p-0" > -{{this.auth.Direccion}} </p>
                <p *ngIf="!this.auth.Direccion" class="m-0 p-0" style="color: red;" class="widget-desc" >Direccion: **Obligatorio </p>
            </div>
          <div class="m-t-15">
          <a  *ngIf="!markerInicio[0]" (click)="vistaInicio()" (click)="toggleCardVisibility('envia')" class="pull-right fs-6" style="color: red;" >*Ubicar en mapa (obligatorio)</a> 
          <a  *ngIf="markerInicio[0]" (click)="vistaInicio()" (click)="toggleCardVisibility('envia')" style="color: forestgreen;" class="pull-right fs-6 fw-light">Cambiar ubicacion en mapa</a>
          </div>
      </div>
      </div>
      <div class="widget-reminder-container">
          <div class="widget-reminder-divider bg-primary"></div>
          <div class="widget-reminder-content">
             <h4 *ngIf="pquienenvia" class="widget-title">{{Precio1|currency:'Q.':'code'}}</h4>
              <h4 *ngIf="!pquienenvia" class="widget-title">Q.0.00</h4>
          </div>

    <div class="widget-reminder-content">
    <label class="mb-0 mt-2" for="selectBanco">Banco a liquidar:</label>
    <select id="selectBanco" class="form-control  mt-0" (change)="onBancoSelected($event.target.value)" name="selectBanco">
    <option value="" disabled [selected]="!selectedBanco">-- Selecciona un banco --</option>
    <option *ngFor="let banco of bancos" [value]="banco.id" [selected]="selectedBanco === banco.id">{{banco.alias}}</option>
   </select>
   <div class="container" >
    <div class="row text-center mb-2 col-lg-4 col-md-10">
      <div *ngIf="!selectedBanco" style="color: red;">Banco: **Obligatorio</div>
      <button class="btn btn-success mt-1"  (click)="agregarBanco()" >AGREGAR BANCO </button> 
    </div>
   </div>
    </div>

      </div>
      <div *ngIf="markerInicio[0]">
      </div>
        <!-- GUARDAR UBICACION -->
        <button *ngIf="!VistaDirecciones1 && markerInicio[0]" (click)="GuardarUbicacion(markerInicio[0].lat, markerInicio[0].lng)" class="btn btn-success col-6">GUARDAR</button>
      
        <!-- UBICACIONES GUARDADAS -->
        <button *ngIf="VistaSeleccionar" (click)="SeleccionarUbicacion()" class="btn btn-dark col-6 ">UBICACIONES</button>
      
        <!-- EDITAR -->

      <button (click)="editarInicio()" type="button" class="btn btn-info col-12"> <h5>EDITAR </h5> </button>
    </div>
  </div>
  <div id="card-container" *ngIf="isCardVisible && VistaEnvio">
    <div class="widget widget-reminder2 col-lg-6 col-md-6">
        <h5 class="text-center">RECIBE</h5>
          <div class="close-button" (click)="toggleCardVisibility('recibe')">&#10006;</div>
          <div >
        <h5 class="card-title" ><strong *ngIf="this.zonafinal" >Zona de Entrega: {{this.zonafinal}} </strong> <span *ngIf="!this.zonafinal" style="color: red;">Zona de Entrega: **Obligatorio </span></h5>
          </div>
          <div class="widget-reminder-container">
              <div class="widget-reminder-divider bg-warning"></div>
              <div class="widget-reminder-content">
          <div *ngIf="nombreFinal" class="widget-desc">-{{nombreFinal}}</div>
          <div *ngIf="!nombreFinal" class="widget-desc" style="color: red;">Nombre: **Obligatorio </div>
          <div *ngIf="numFinal" class="widget-desc  ">-{{numFinal}}</div>
          <div *ngIf="!numFinal" class="widget-desc" style="color: red;"> Numero: **Obligatorio</div>
          <div *ngIf="dirFinal" class="widget-desc  ">-{{dirFinal}}</div>
          <div *ngIf="!dirFinal" class="widget-desc " style="color: red;"> Direccion: **Obligatorio</div>
          <div class="m-t-15">
              <a  *ngIf="!markerFin[0]" (click)="vistaFin()" class="pull-right fs-6 text-secondary">Ubicar en mapa (sugerido)</a> 
              <a  *ngIf="markerFin[0]" (click)="vistaFin()" style="color: forestgreen;" class="pull-right fs-6 fw-light">Cambiar ubicacion en mapa</a>
          </div>
          </div>
          </div>
      <div class="widget-reminder-container card-body">       
      <div class="widget-reminder-divider bg-warning"></div>
      <div class="widget-reminder-content">
        <span *ngIf="pquienrecibe && cod<= 0">
          <h4  *ngIf="this.Precio1 "class=" widget-title"> <strong> {{this.Precio1|currency:'Q':'code'}} </strong> </h4>  
        </span>
          <span *ngIf="pquienrecibe && cod>0 || cod>0">
              <h4 class=" widget-title"> <strong> {{total|currency:'Q':'code'}} </strong> </h4>  
          </span>
        </div>
      
      </div>
      <div  class="widget text-center card-footer"> 
              
          <button (click)="editarFin()" type="button" class="btn btn-warning col-12 "> <h5> EDITAR DATOS </h5> </button>    

      </div> 
    </div>
  </div>

 
  <div id="card-container" class="card-container">
    <div *ngIf="!isCardVisible" [ngClass]="{'resaltar': VistaRecoger }" id="card" class="card">
      <div id="card-body" class="card-body" (click)="toggleCardVisibility('envia')">
        <h5 id="card-title" class="card-title">Punto de Inicio</h5>

        <p id="card-text" class="card-text" *ngIf="this.zonainicio">{{this.zonainicio}}</p>
        <p id="card-text" class="card-text" *ngIf="!this.zonainicio" style="color: red;">INGRESA ZONA </p>

      </div>
      <div class="mensaje-container" [ngClass]="{'listo': todoListo1, 'falta-info': !todoListo1}">
        {{ mensaje1 }} <br>


          <div *ngIf="pquienenvia || pquienrecibe" class="widget-reminder-content">
             <h4 *ngIf="pquienenvia" class="widget-title">{{Precio1|currency:'Q.':'code'}}</h4>
              <h4 *ngIf="!pquienenvia" class="widget-title">Q.0.00</h4>
          </div>


      </div>
    </div>
    <div *ngIf="!isCardVisible" [ngClass]="{'resaltar1': VistaEnvio }" id="card1" class="card">
      <div id="card-body" class="card-body" (click)="toggleCardVisibility('recibe')">

        <h5 id="card-title" class="card-title">Punto Final</h5>
        <p id="card-text" class="card-text" *ngIf="this.zonafinal">{{this.zonafinal}}</p>
        <p id="card-text" class="card-text" *ngIf="!this.zonafinal" style="color: red;">INGRESA ZONA </p>
      </div>
      <div class="mensaje-container" [ngClass]="{'listo': todoListo, 'falta-info': !todoListo}">
        {{ mensaje }} <br>
        <div *ngIf="pquienenvia || pquienrecibe" class="widget-reminder-content">
          <span *ngIf="pquienrecibe && cod<= 0 || pquienrecibe && cod === 0 ">
            <h4 class=" widget-title"> <strong> {{this.Precio1|currency:'Q':'code'}} </strong> </h4>  
          </span>
            <span *ngIf="pquienrecibe && cod > 0 || cod > 0">
                <h4 class=" widget-title"> <strong> {{total|currency:'Q':'code'}} </strong> </h4>  
            </span>
          <span *ngIf="!pquienrecibe && !cod || !pquienrecibe && cod<=0 ">
              <h4 class="widget-title">Q.0.00</h4>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="VistaDirecciones" class="fullscreen-modal">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/boxicons@2.0.7/css/boxicons.min.css">
    <div class="modal-content">

      <span (click)="SeleccionarUbicacion()" class="close-button">&times;</span> <!-- Aquí se agrega el botón de cierre (X) -->
      <h2 class="text-white text-center">SELECCIONA UNA UBICACION</h2>
      <div class="modal-inner">

        <div class="row pt-2 row-cols-1 row-cols-lg-2 row-cols-xl-4">
          <div *ngFor="let item of this.UBICACIONES$ | async; let i = index" class="col">
            <div class="card radius-15">

              <button (click)="toggleFavorito(item)" class="btn btn-dark">
                <i class="fa fa-star" [ngClass]="{'favorito': item.PRINCIPAL, 'no-favorito': !item.PRINCIPAL}"></i>
              </button>
              <div class="card-body ">

                <div class="border radius-15 p-2">

                  <div class="text-left">
                    <h6 class="mb-2"><i class="fas fa-user"></i> Nombre: {{item.Nombre}}</h6>
                    <p class="mb-1"><i class="fas fa-phone"></i> Número: {{item.Numero}}</p>
                    <p class="mb-1"><i class="fas fa-map-marker-alt"></i> Dirección: {{item.Direccion}}</p>
                    <p><i class="fas fa-envelope"></i> Zona: {{item.Zona}}</p>
                    <div class="bank-name">
                      <span class="badge badge-dark">{{getBankName(item.Banco)}}</span>
                    </div>
                  </div>
        <!-- Botón para marcar/desmarcar como favorito -->

                  <div class="d-grid">
                    <button (click)="Seleccionada(item.Banco,item.Coordenadas,item.Direccion,item.Nombre,item.Numero,item.Zona)" class="btn btn-success">SELECCIONAR</button>
                    <button (click)="editarguardadas(item.Referencia)" class="btn btn-info">EDITAR</button>
                    <button (click)="ELIMINARUBICACION(item.Referencia)" class="btn btn-danger">ELIMINAR</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  






</div>
<!--FIN esto es *ngIf="pedidoEnviado" una prueba------------------------------------------->  

        <div id="contenido" style="display: none;"  class="container p-0">
          <br><br><br> 
          <button type="button" class="btn btn-secondary m-3 text-center" (click)="recargarPagina()"><h1>NUEVO PEDIDO</h1></button>
          <div class="col-lg-12 col-md-12  col-xl-6 p-0">
                <div class="invoice p-0">
                    <!-- botones impresion y pdf -->
                    <div class="invoice-company text-inverse f-w-600">
                        <button class="btn btn-lg btn-black m-2 " (click)="downloadImage(guia)" > <strong> <i class="fas fa-image"></i> GUARDAR </strong></button>
                        
                        <!-- <button class="btn btn-lg btn-black m-2" onclick="window.print()"><i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> IMPRIMIR</button>                         -->
                    </div>
                    <!--GENERACION DE GUIA-------------->   
                    <div class="invoice-content " id="htmlData">
                        <div class="container discontinua col-sm-12  d-flex p-2 "  >
                            <div class="container profile-page">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="card profile-header">
                                            <div class="body p-0">
                                                <div class="row">  
                                                      <div class="col-12 text-center pb-5">
                                                        <canvas id="barcode" src="" alt="Código de barras"> </canvas>
                                                       <h1>{{guia}} </h1> 
                                                      </div>
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="row" >
                                                            <div *ngIf="personal" class="col-sm-12 col-md-12 col-lg-6 note note-light">
                                                                <h3>DE:</h3>
                                                                <span class="job_post"><strong>{{this.auth.NombreUsuario}} </strong> </span><br>
                                                                <span class="job_post"><strong>{{this.auth.numero}} </strong> </span>
                                                            </div>
                                                            <div *ngIf="empresa" class="col-sm-12 col-md-12 col-lg-6 note note-light">
                                                                <h3>DE:</h3>
                                                                <span class="job_post"><strong>{{this.auth.AvatarE.Nombre}} </strong></span><br>
                                                                <span class="job_post"><strong>{{this.auth.AvatarE.Numero}}</strong></span>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 col-lg-6 note note-light">
                                                                <h3>PARA:</h3>
                                                                <span class="job_post">{{nombreFinal}}</span><br>
                                                                <span class="job_post">{{numFinal}}</span><br>
                                                            </div> 
                                                            <div class="col-12 col-md-12 col-lg-12 note note-light">
                                                              <h3>DIRECCION:</h3>
                                                              <span class="job_post">{{dirFinal}}</span>
                                                          </div>                                                 
                                                        </div>  
                                                    </div>
                                                    <div class="invoice-price p-0">


                                                        <div class="invoice-price-right">
                                                          

                                                              <div *ngIf="this.pago === '2'"  class="row tex-end">
                                                                <div class="col-12">
                                                                  <h1 class="f-w-600 m-1">{{ total | currency:'Q':'code'}}</h1>
                                                                  </div>
                                                               </div> 
                                                              <div *ngIf="this.pago === '1'" class="row tex-end">
                                                              <div class="col-12">
                                                                <h1 class="f-w-600 m-1">{{ cod | currency:'Q':'code' }}</h1>   
                                                              </div>
                                                              </div>                                                           

                                                          </div>
                                                          


                                                    </div>
                                                    <div class="note note-light text-center"> 
                                                      <div class="row">
                                                        <div class="col-6 text-left">
                                                         {{ this.fechajavas | date: 'HH:mm --> dd/MM/yyyy ' }}
                                                        </div>
                                                        <div class="col-6">

                                                          <strong> Pedido enviado con la tecnologia de VIVER</strong>
                                                        </div>
                                                      </div>
                                                      
                                                    </div>
                                                </div>
                                            </div>                    
                                        </div>
                                    </div>
                                </div>
                            
                            </div>

                        </div>
                        <!-- begin invoice-price -->
                       
                        <!-- end invoice-price -->
                    </div>
                    <!-- end invoice-content -->
                </div>
            </div>
        </div>
        
        <div id="spinner" class="spinner-grow " style="width: 10rem; height: 10rem; display: none;" role="status" >
          <span class="visually-hidden">Loading...</span>
        </div>

      </div>
    </div>
  </div>
</div>
<span>

</span>
<footer *ngIf="!pedidoEnviado" class="text-center fixed-bottom" style="background-color: #ccdded00;">
    <div class="btn-group btn-group-lg" aria-label="Basic example" role="group">
 
      <button *ngIf="(this.zonafinal && this.zonainicio && personal && auth.NombreUsuario && auth.numero && numFinal && nombreFinal && markerInicio[0] && dirFinal && this.auth.Direccion) || (this.zonafinal && this.zonainicio && empresa && auth.AvatarE.Nombre && auth.AvatarE.Numero) && numFinal && nombreFinal && markerInicio[0] && dirFinal && this.auth.Direccion" 

              (click)="funcionPago()" type="button" class="btn btn-success"> MONTOS</button>
  
      <button *ngIf="(this.selectedBanco && this.Precio1 && this.zonafinal && personal && auth.NombreUsuario && auth.numero && numFinal && nombreFinal && markerInicio[0] && dirFinal && this.auth.Direccion && (pago === '1' || pago === '2')) || ( this.Precio1 && this.zonafinal && empresa && auth.AvatarE.Nombre && auth.AvatarE.Numero) && numFinal && nombreFinal && markerInicio[0] && dirFinal && this.auth.Direccion && (pago === '1' || pago === '2')"

              type="button" class="btn btn-warning" (click)="enviarPedido(); Ocultar()"  > ENVIAR</button>
    </div>
</footer>