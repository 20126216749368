<br><br>
<div id="mi-componente" class=" border border-5 border-warning">
    <div class="row">
        
        <div class="col-12 p-0 ">
          <!-- Panel Chat -->
          <div class="panel" id="chat">
            <div class="panel-body">
              <div class="chats " *ngFor="let message of mensajes" >
                <div class="chat"  [ngClass]="{'chat-left': message.de === 'Conductor'}" >
                  <div class="chat-avatar">
                    <a class="avatar avatar-online" data-toggle="tooltip" href="#" data-placement="right" title="" data-original-title="June Lane">
                      <img src="../../../../assets/{{12}}.png" alt="...">
                      <i></i>
                    </a>
                  </div>
                  <div class="chat-body">
                    <div class="chat-content">
                      <time  >
                       <span class="fw-bold"> {{ message.de }}: </span> 
                      </time>
                      <p class="fw-lighter"> <em>  {{ message.msj }} </em>
                       
                      </p>
                      <p class="text-star"  > {{ message.tiempo | date:'dd/MM/yy, h:mm a' }} </p>

                    </div>
                  </div>
    
                </div>
    
    
    
    
    
              </div>
            </div>
            <br><br><br>
            <br><br><br>
            <div class=" fixed-bottom d-flex justify-content-center text-center pb-3">
                <button (click)="abrirChat()" type="button" class="btn btn-dark btn-rounded btn-lg"><span class="text-danger">ENVIAR UN MENSAJE</span></button>
            </div>
    
          </div>
          <!-- End Panel Chat -->
        </div>
    </div>
</div>