import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SendEmailComponent } from './auth/send-email/send-email.component';
import { CanEditGuard } from './auth/guards/can-edit.guard';
import { CanHomeGuard } from './auth/guards/can-home.guard';
import { CanLoginGuard } from './auth/guards/can-login.guard';
import { ChatComponent } from './shared/chat/chat.component';
import { RutaComponent } from './shared/ruta/ruta.component';
import { CotizarComponent } from './cotizar/cotizar.component';
import { ProductosComponent } from './envios/productos/productos.component';

const routes: Routes = [
  { path:'',
    redirectTo:'/home',
    pathMatch: 'full',
  },
  { path: 'ruta', component: RutaComponent ,canActivate: [CanHomeGuard] },
  { path: 'ruta/:variable', component: RutaComponent, canActivate: [CanHomeGuard] },
  { path: 'ruta/:variable/:guia', component: RutaComponent, canActivate: [CanHomeGuard] },
  { path: 'chat', component: ChatComponent , canActivate: [CanHomeGuard]   },
  { path: 'chat/:variable', component: ChatComponent },
  { path: 'home', 
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [CanHomeGuard]
  },
  { path: 'login', 
    loadChildren: () => import('./auth/login/login.module').then((m) => m.LoginModule),
canActivate: [CanLoginGuard]
  },
  { path: 'register', 
    loadChildren: () => import('./auth/register/register.module').then((m) => m.RegisterModule),
    // canActivate: [CanLoginGuard]
  },
  { path: 'verification-email',
  component:SendEmailComponent,
  },
  { path: 'forgot-password', 
  loadChildren: () => import('./auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
   
  { path: 'usuario', 
    loadChildren: () => import('./usuario/usuario.module').then(m => m.UsuarioModule),
    canActivate: [CanEditGuard]
},

{ 
  path: 'cotizar',
  component:CotizarComponent ,
},
{
  
  path: 'depar',
  component:ProductosComponent ,
},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
