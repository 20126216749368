<span *ngIf="showNavbar">
  <nav  *ngIf="user$ | async as user" class="navbar p-0 fixed-top navbar-light bg-light">
    <div class="container p-0">  
          <div *ngIf="user$ | async as user else loading" class="col-2">
            <span  *ngIf="user" class="col-2" style="font-size: 2em; color: black;">
              <a href="/#/home">
               <i class="fas fa-user-tie"></i>
              </a>
            </span>
          </div>
          <ng-template #loading>
            <span class="col-2" style="font-size: 2em; color:#D9D7D7;">
          
              <i class="fas fa-user-times"></i>
  
          </span>
          </ng-template>
  
          <div class="col-8 text-center">
            <a href="/#/usuario">
          <img src="./../../../assets/NOMBRE.png" class="img-fluid rounded" style="max-width: 125px">
            </a>  
          </div>
          <div class="col-2 text-right">
            <ul class="navbar-nav mx-auto" *ngIf= "user$ | async as user else loadings">
              <li *ngIf="user">
                <div (click)="onLogout()"  class="m-0 form-check form-switch">
                  <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked >
                  <label class="form-check-label" for="flexSwitchCheckChecked"></label>
                </div>          
              </li>         
            </ul> 
                <ng-template #loadings>
                  <div  class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled />
                    <label class="form-check-label" for="flexSwitchCheckDisabled"></label>
                  </div>          
                </ng-template>
          </div>
    </div>
  </nav> <br>
</span>