import { Component, ViewChild } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { ActivatedRoute } from '@angular/router';
import { WebsocketService } from '@app/services/websocket.service';
@Component({
  selector: 'app-root',
  templateUrl: './ruta.component.html',
})
export class RutaComponent extends Socket {
  title = 'Mi aplicación de Angular';
  markerPositions: google.maps.LatLngLiteral[] = [];
  lat:any = 14.595756;
  lng:any = -90.520971;
  guia: any;

  constructor(private route: ActivatedRoute,public socketService : WebsocketService) {
    super({
      url: environment.serverSocket
    });
  }

  ngOnInit() {
    this.guia = this.route.snapshot.paramMap.get('variable');
    // Conexión WebSocket
    this.ioSocket.on('disconnect', () => {
      console.log('Desconectado del WebSocket');
    });

    // Obtener la posición inicial del conductor
    navigator.geolocation.getCurrentPosition((position) => {
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
      this.Coordenadas();

      // Agregar la posición a markerPositions
      this.markerPositions.push({
        lat: +this.lat,
        lng: +this.lng
      });
    });

    // Actualizar periódicamente la posición del conductor
    setInterval(() => {
      this.actualizarPosicion();
    }, 5000); // Actualizar cada 5 segundos
  }

  ngOnDestroy(): void {
    // Desuscribirse de la sala correspondiente
      this.socketService.desuscribirse(this.guia);
      // Cancelar la suscripción a los mensajes
      localStorage.removeItem('isTimeoutActivated');
    
  }



  actualizarPosicion() {
    // Generar una posición aleatoria cerca de la posición actual del conductor
    const newLat = this.lat + (Math.random() - 0.5) * 0.01;
    const newLng = this.lng + (Math.random() - 0.5) * 0.01;

    // Emitir las nuevas coordenadas a través del WebSocket
    this.ioSocket.emit('coordenadas', {
      lat: newLat,
      lng: newLng
    });

    // Actualizar la posición del marcador en el mapa
    this.lat = newLat;
    this.lng = newLng;
    this.markerPositions = [{
      lat: newLat,
      lng: newLng
    }];
  }
  markerOp = {
    draggable: false,
    icon: './../../assets/marker2.png'
  }
  map = {
    draggable: false,
    gestureHandling: "greedy",
    fullscreenControl: false,
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    mapTypeControl : false,
    clickableIcons : false,
    keyboardShortcuts : false,
    streetViewControl:true,
  }
  Coordenadas() {
    let lat = this.lat;
    let lng = this.lng;
    this.ioSocket.emit('coordenadas', {
      lat,
      lng
    });
  }

  @ViewChild(MapInfoWindow, {
    static: false
  }) infoWindow: MapInfoWindow;
  @ViewChild(GoogleMap, {
    static: false
  }) mapa: GoogleMap;
}

